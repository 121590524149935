export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#AE9CCD"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.52256 20.7009C5.09856 21.2239 5.84456 21.4829 6.60256 21.4829C7.46656 21.4819 8.34656 21.1469 9.00656 20.4869L12.7426 16.7519C15.3896 17.6749 18.3366 17.0109 20.3346 15.0149C22.4226 12.9259 23.0526 9.8169 21.9396 7.0939C21.8766 6.9379 21.7396 6.8259 21.5756 6.7919C21.4106 6.7609 21.2416 6.8099 21.1236 6.9289L17.7246 10.3289C17.5346 10.5179 17.2646 10.6019 17.0026 10.5479L15.3976 10.2309C15.0796 10.1679 14.8326 9.9219 14.7686 9.6029L14.4486 7.9919C14.3956 7.7289 14.4776 7.4599 14.6676 7.2699L18.0656 3.8709C18.1846 3.7529 18.2356 3.5829 18.2026 3.4179C18.1686 3.2539 18.0556 3.1179 17.8996 3.0549C15.1726 1.9489 12.0626 2.5799 9.98056 4.6609C7.98356 6.6579 7.32156 9.6069 8.24956 12.2599L4.40856 16.0999C3.79656 16.7129 3.45856 17.5599 3.47956 18.4259C3.50156 19.3019 3.87156 20.1099 4.52256 20.7009Z" />
    </svg>
  )
}
