import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  return (
    <svg width={30} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x={0.75}
        y={0.75}
        width={28.5}
        height={20.5}
        rx={2.25}
        stroke={darkTheme ? '#DECBFE' : '#AE9CCD'}
        strokeWidth={1.5}
      />
      <path
        d="M8.279 15.132c-1.115 0-2.046-.352-2.794-1.056-.74-.711-1.111-1.624-1.111-2.739 0-1.115.37-2.024 1.111-2.728.748-.711 1.68-1.067 2.794-1.067 1.371 0 2.394.598 3.069 1.793l-1.342.66a1.967 1.967 0 0 0-.715-.759 1.787 1.787 0 0 0-1.012-.308c-.667 0-1.217.227-1.65.682-.433.455-.649 1.03-.649 1.727 0 .697.216 1.272.649 1.727.433.455.983.682 1.65.682.367 0 .704-.099 1.012-.297.315-.198.554-.455.715-.77l1.342.649c-.697 1.203-1.72 1.804-3.069 1.804Zm6.606 0c-1.306 0-2.34-.389-3.102-1.166l.847-1.188c.63.66 1.408.99 2.332.99.403 0 .715-.077.935-.231.227-.161.34-.356.34-.583 0-.198-.106-.363-.318-.495a2.172 2.172 0 0 0-.781-.286 10.183 10.183 0 0 1-2.035-.627 1.92 1.92 0 0 1-.781-.66c-.206-.3-.308-.667-.308-1.1 0-.63.253-1.159.759-1.584.506-.433 1.18-.649 2.024-.649 1.166 0 2.12.341 2.86 1.023l-.87 1.144c-.579-.535-1.29-.803-2.133-.803-.33 0-.587.066-.77.198a.63.63 0 0 0-.275.539c0 .176.102.326.308.451.212.117.473.205.78.264.316.059.653.147 1.013.264.366.11.704.238 1.012.385.315.14.575.363.78.671.213.3.32.664.32 1.089 0 .704-.253 1.272-.76 1.705-.498.433-1.224.649-2.177.649ZM22.889 15H20.93l-2.837-7.337h1.782l2.035 5.643 2.023-5.643h1.782L22.89 15Z"
        fill={darkTheme ? '#DECBFE' : '#AE9CCD'}
      />
    </svg>
  )
}
