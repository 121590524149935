import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={darkTheme ? '#decbfe' : '#ae9ccd'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.399 17.244C11.003 17.244 9.659 16.653 8.712 15.623C8.432 15.318 8.453 14.844 8.758 14.564C9.062 14.285 9.537 14.304 9.818 14.609C10.49 15.341 11.407 15.744 12.399 15.744C14.325 15.744 15.892 14.177 15.892 12.251C15.892 10.325 14.325 8.758 12.399 8.758C10.941 8.758 9.708 9.629 9.181 10.888L9.996 10.543C10.374 10.384 10.816 10.559 10.978 10.942C11.14 11.323 10.962 11.763 10.58 11.925L8.313 12.884C8.22 12.923 8.121 12.943 8.021 12.943C7.925 12.943 7.83 12.924 7.739 12.888C7.554 12.813 7.408 12.668 7.331 12.485L6.368 10.206C6.206 9.824 6.384 9.384 6.766 9.223C7.147 9.063 7.588 9.24 7.749 9.622L7.926 10.041C8.736 8.387 10.42 7.258 12.399 7.258C15.152 7.258 17.392 9.497 17.392 12.251C17.392 15.004 15.152 17.244 12.399 17.244ZM16.217 2.75H7.783C4.623 2.75 2.5 4.971 2.5 8.276V16.224C2.5 19.529 4.624 21.75 7.784 21.75H16.217C19.377 21.75 21.5 19.529 21.5 16.224V8.276C21.5 4.971 19.377 2.75 16.217 2.75Z"
      />
    </svg>
  )
}
