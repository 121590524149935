import { Icon } from '@components'
import s from './PushNotifications.module.scss'
import { CSSTransition } from 'react-transition-group'
import './animations.scss'
import { useEffect, useState } from 'react'
import { notificationsTranslateFn } from '@src/utils'
import { useTranslation } from 'react-i18next'

export default function NotificationItem({ message, onClick }) {
  const { t } = useTranslation(['container'])

  const [isShow, setIsShow] = useState(false)
  useEffect(() => {
    setIsShow(true)
  }, [])

  const onClickHandler = () => {
    setIsShow(false)
    setTimeout(() => {
      onClick()
    }, 300)
  }

  return (
    <CSSTransition in={isShow} classNames="push" unmountOnExit timeout={600}>
      <li className="push">
        <button className={s.item_button} onClick={onClickHandler}>
          <Icon className={s.item_close} name="Cross" />
          <p className={s.item_text}>{notificationsTranslateFn(message, t)}</p>
        </button>
      </li>
    </CSSTransition>
  )
}
