import dayjs from 'dayjs'
import parseDateByServerTime from './parseDateByServerTime'

export default function getFormatedDate(dateProp, timeProp = '00:00:00') {
  const convertedDate = dayjs.tz(parseDateByServerTime(`${dateProp} ${timeProp}`))

  const date = convertedDate.format('L')
  const timeValue = convertedDate.format('LTS')

  const suffix = timeValue.match(/PM|AM/)
  const time = `${timeValue.split(':', 2).join(':')} ${suffix ? suffix[0] : ''}`

  return { date, time, fullString: `${date} ${time}` }
}
