import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import s from './IPv6Check.module.scss'
import { Icon } from '@components'
import { checkIPv6 } from '@utils'

export default function IPv6Check({ className }) {
  const { t } = useTranslation(['cloud_vps'])

  const [isLoading, setIsLoading] = useState(false)
  const [isIPv6Supported, setIsIPv6Supported] = useState(null)
  const [isIPv6RequestError, setIsIPv6RequestError] = useState(null)

  const checkIPv6Support = () =>
    checkIPv6({ setIsIPv6Supported, setIsLoading, setIsIPv6RequestError })

  useEffect(() => {
    checkIPv6Support()
  }, [])

  return (
    <div className={cn(s.wrapper, className)}>
      {isLoading ? (
        <div className={cn(s.row, s.checking)}>
          <Icon name="Loader" className={s.loader} /> {t('checking_ipv6')}
        </div>
      ) : (
        <div className={s.row}>
          <span className={cn(s.status, { [s.success]: isIPv6Supported })}>
            {isIPv6RequestError ? (
              <>
                <Icon name="Cross" className={s.status_icon} fill="#fff" />{' '}
                {t('ipv6_error')}
              </>
            ) : isIPv6Supported ? (
              <>
                <Icon name="Check" className={s.status_icon} stroke="#fff" />{' '}
                {t('ipv6_supported')}
              </>
            ) : (
              <>
                <Icon name="Cross" className={s.status_icon} fill="#fff" />{' '}
                {t('ipv6_not_supported')}
              </>
            )}
          </span>
          <button onClick={checkIPv6Support} className={s.recheck}>
            <Icon name="Update" /> {t('recheck')}
          </button>
        </div>
      )}
      {isIPv6Supported !== null && (
        <p className={s.warn}>
          <Icon name="Info" className={s.warn_icon} /> {t('ipv6_check_warn_text')}
        </p>
      )}
    </div>
  )
}
