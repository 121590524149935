import { useEffect, useRef, useState } from 'react'
import s from './InstancesList.module.scss'
import cn from 'classnames'
import {
  CopyText,
  EditCell,
  TooltipWrapper,
  InstancesOptions,
  AutoprolongIcon,
  Status,
} from '@components'
import * as route from '@src/routes'
import { useNavigate } from 'react-router-dom'
import {
  getFlagFromCountryName,
  getInstanceMainInfo,
  formatCountryName,
  cutDcSuffix,
  getImageIconName,
} from '@utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function InstanceItem({ item, editInstance }) {
  const { t } = useTranslation([
    'cloud_vps',
    'vds',
    'countries',
    'filter',
    'dedicated_servers',
  ])

  const optionsCell = useRef()
  // const checkboxCell = useRef()
  const servernameCell = useRef()
  const ipCell = useRef()
  const navigate = useNavigate()
  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  const [serverName, setServerName] = useState(item.servername?.$ || '')

  const { isNotActive } = getInstanceMainInfo(item)

  const editServerName = value => {
    const slicedValue = value.slice(0, 100)
    editInstance({
      value: slicedValue,
      elid: item.id.$,
      errorCallback: () => setServerName(serverName),
    })
    setServerName(value)
  }

  useEffect(() => {
    setServerName(item.servername?.$ || '')
  }, [item.servername?.$])

  const itemCountry = formatCountryName(item, t)

  const ip = item?.ip?.$ || item?.ip_v6?.$

  const osIcon = getImageIconName(item?.os_distro?.$, darkTheme)

  const tooltipContent = () => {
    if (!item?.os_distro?.$ && !item?.os_version?.$) {
      return t('select.os_distro.detecting', { ns: 'filter' })
    }

    const osDistro = item?.os_distro?.$ ? item.os_distro.$ : ''
    const osVersion = item?.os_version?.$ ? item.os_version.$ : ''

    return `${osDistro} ${osVersion}`.trim()
  }

  return (
    <tr
      className={cn(s.tr, { [s.disabled]: isNotActive })}
      onClick={e => {
        if (
          optionsCell.current.contains(e.target) ||
          // checkboxCell.current.contains(e.target) ||
          servernameCell.current.contains(e.target) ||
          ipCell.current.contains(e.target) ||
          isNotActive
        )
          return
        navigate(`${route.CLOUD_VPS}/${item.id.$}`, { state: item })
      }}
    >
      {/* <td ref={checkboxCell}>
        <CheckBox />
      </td> */}
      <td ref={servernameCell} className={cn(s.td, s.servername_cell)}>
        <EditCell
          originName={serverName}
          className={s.edit_name}
          onSubmit={editServerName}
          placeholder={t(serverName || t('server_placeholder', { ns: 'vds' }), {
            ns: 'vds',
          })}
        />
        <div className={s.servername_cell_row}>
          <div className={cn(s.servername_cell_row, s.icons)}>
            {item?.datacentername && (
              <TooltipWrapper
                className={s.popup}
                wrapperClassName={cn(s.popup__wrapper, s.popup__wrapper_flag)}
                content={itemCountry?.DCName}
              >
                <img
                  src={require(`@images/countryFlags/${getFlagFromCountryName(
                    itemCountry?.countryName,
                  )}.png`)}
                  width={20}
                  height={14}
                  alt={itemCountry?.countryName}
                />
              </TooltipWrapper>
            )}
            {osIcon && (
              <TooltipWrapper
                className={s.popup}
                wrapperClassName={s.popup__wrapper}
                content={tooltipContent()}
              >
                <img
                  src={require(`@images/soft_os_icons/${osIcon}.png`)}
                  alt={item?.os_distro?.$}
                />
              </TooltipWrapper>
            )}
          </div>
          <Status item={item} />
        </div>
      </td>
      <td className={s.td}>{cutDcSuffix(item.pricelist.$)}</td>
      <td className={s.td}>
        {item.cost.$.replace('Day', t('day'))
          .replace('Month', t('Month', { ns: 'dedicated_servers' }))
          .replace('Year', t('Year', { ns: 'dedicated_servers' }))}
      </td>
      <td className={s.td}>
        {item?.datacentername && (
          <TooltipWrapper
            className={s.popup}
            wrapperClassName={cn(s.popup__wrapper, s.popup__wrapper_flag)}
            content={itemCountry?.DCName}
          >
            <img
              src={require(`@images/countryFlags/${getFlagFromCountryName(
                itemCountry?.countryName,
              )}.png`)}
              width={20}
              height={14}
              alt={itemCountry?.countryName}
            />
          </TooltipWrapper>
        )}
      </td>
      <td className={s.td}>
        {item.createdate.$} <br />
        {item.i_opentime?.$}
      </td>
      <td className={s.td}>
        {item.real_expiredate?.$}
        <br />
        <div className={s.td_row}>
          {item.i_expiretime?.$}
          <AutoprolongIcon item={item} />
        </div>
      </td>
      <td className={s.td}>
        {osIcon && (
          <TooltipWrapper
            className={s.popup}
            wrapperClassName={s.popup__wrapper}
            content={tooltipContent()}
          >
            <img
              src={require(`@images/soft_os_icons/${osIcon}.png`)}
              alt={item?.os_distro?.$}
            />
          </TooltipWrapper>
        )}
      </td>
      <td className={s.td}>
        <div className={s.ip_cell} ref={ipCell}>
          <span>{ip}</span>
          {ip && (
            <div className={s.fade_in}>
              <CopyText text={ip} promptText={t('ip_address_copied')} />
            </div>
          )}
        </div>
      </td>
      <td className={s.td} ref={optionsCell}>
        <InstancesOptions item={item} />
      </td>
    </tr>
  )
}
