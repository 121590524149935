export default function SvgComponent(props) {
  return (
    <svg width={16} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.085 6.59a.443.443 0 0 0-.442.444v6.517c0 .245.198.444.442.444h2.667V6.59H1.085ZM15.593 9.04c0-.563-.302-1.057-.752-1.327a1.547 1.547 0 0 0-1.27-2.425h-3.257c.1-.454.237-1.134.332-1.847.247-1.854.078-2.882-.532-3.236-.38-.22-.791-.265-1.156-.126-.281.108-.661.372-.878 1.021l-.856 2.247C6.79 4.422 5.461 5.55 4.637 6.174v8.017c1.527.537 3.121.809 4.748.809h3.549a1.547 1.547 0 0 0 1.31-2.361 1.55 1.55 0 0 0 .597-2.27c.45-.271.752-.765.752-1.328Z"
        fill="#D4CDDD"
      />
    </svg>
  )
}
