import s from './AccessRightsModal.module.scss'
import { useState } from 'react'
import ListItem from './ListItem'

export default function List({
  items,
  userId,
  nested,
  isRightsChanged,
  setIsRightsChanges,
  plid,
}) {
  const [openedCategory, setOpenedCategory] = useState('')

  return (
    <ul className={s.sublist}>
      {items
        .filter(
          subItem =>
            !(
              subItem.name.$.includes('service.') || subItem.name.$.includes('gotoserver')
            ),
        )
        .map(subItem => {
          return (
            <ListItem
              key={subItem.name?.$}
              item={subItem}
              userId={userId}
              openedCategory={openedCategory}
              setOpenedCategory={setOpenedCategory}
              nested={nested}
              isRightsChanged={isRightsChanged}
              setIsRightsChanges={setIsRightsChanges}
              plid={plid}
            />
          )
        })}
    </ul>
  )
}
