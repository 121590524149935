export default function getImageInfo(item) {
  if (!item) return {}

  const imageStatus = item.fleio_status?.$.trim().toLowerCase().replaceAll(' ', '_')
  const billingStatus = item.item_status?.$.trim().toLowerCase().replaceAll(' ', '_')
  const isDeleting = billingStatus === 'deletion_in_progress'

  const status = isDeleting ? billingStatus : imageStatus || billingStatus
  const displayStatus = (isDeleting ? billingStatus : imageStatus)?.replaceAll('_', ' ')

  return {
    imageStatus,
    billingStatus,
    isDeleting,
    displayStatus,
    status,
  }
}
