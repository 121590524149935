const DC_COUNTRIES = ['Netherlands', 'Poland', 'Staging']

/**
 *
 * @param {Object | String} item
 * @returns {Object | undefined}
 */
export default function formatCountryName(
  data,
  t,
  key = 'datacentername',
) {
  let inputString
  if (typeof data === 'object' && data !== null) {
    inputString = data?.[key]?.$
  } else {
    inputString = data
  }

  const countryName = DC_COUNTRIES.find(country =>
    inputString?.toLowerCase()?.includes(country?.toLowerCase()),
  )

  if (countryName) {
    const regex = new RegExp(`${countryName}-(\\d+)`, 'i')
    const match = inputString?.match(regex)

    let DCName
    if (match) {
      DCName = `${t(countryName, { ns: 'countries' })}-${match[1]}`
    } else if (inputString?.toLowerCase().includes(countryName.toLowerCase())) {
      DCName = t(countryName, { ns: 'countries' })
    }

    return { countryName, DCName }
  }

  console.error('Neither country name nor datacenter name parse succeeded')
  return undefined
}
