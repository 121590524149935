import s from './ImagesList.module.scss'
import PropTypes from 'prop-types'
import { getImageInfo } from '@utils'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

export default function ImageMobileItem({ item, cells, itemOnClickHandler, idKey }) {
  const nameField = cells.find(cell => cell.label === 'name')
  const optionsField = cells.find(cell => cell.label === 'options')

  const { isDeleting } = getImageInfo(item)
  const { t } = useTranslation(['cloud_vps'])

  const onKeyUpHandler = e => {
    if (e.code === 'Enter') {
      itemOnClickHandler(e, item)
    }
  }

  return (
    <div
      className={cn(s.mobile_item, { [s.disabled]: isDeleting })}
      onClick={e => !isDeleting && itemOnClickHandler(e, item)}
      tabIndex={0}
      onKeyUp={onKeyUpHandler}
      role="link"
    >
      <div className={s.mobile_item__header} data-target="options">
        <div className={s.mobile_item__header_name}>
          {nameField.renderData?.(item[nameField.value]?.$, item) ??
            item[nameField.value]?.$}
        </div>
        <div>{optionsField?.renderData(undefined, item)}</div>
      </div>

      <div className={s.mobile_item__body}>
        {cells.map(cell => {
          if (cell.label === 'name' || cell.label === 'options') return null
          return (
            <div
              className={s.mobile_item__value}
              key={`item_m_${item?.[idKey].$}${cell.label}`}
              data-target={cell.label}
            >
              <p className={s.mobile_item__param}>{t(cell?.label)}</p>

              <p>{cell.renderData?.(item[cell.value]?.$, item) ?? item[cell.value]?.$}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

ImageMobileItem.propTypes = {
  item: PropTypes.object,
  cells: PropTypes.array,
  itemOnClickHandler: PropTypes.func,
  idKey: PropTypes.string,
}
