import { selectors, actions, authActions } from '@redux'
import { ERROR_ALREADY_HANDLED, SUPPORT_MAIL } from '@utils/constants'
import cookies from '@utils/cookies'
import { toast } from 'react-toastify'

export const applyInterceptors = instance => {
  // instance.interceptors.request.use(requestHandler)

  instance.interceptors.response.use(null, errorHandler)
}

// function requestHandler(config) {
//   config.baseURL += '/403/'
//   return config
// }

async function errorHandler(err) {
  const { dispatch, getState } = require('@redux/store')
  const onlineStatus = selectors.onlineStatus(getState())

  /**
   * 503 - billmgr is locked
   * 423 - nginx code that we get in case of maintenance work
   * 460 - nginx code that we get when client`s IP is blocked
   * because of bruteforce or using black vpn
   * 403 - access is Forbidden (CloudFlare)
   * For now we don`t get 403 code from CloudFlare
   */
  const NETWORK_ERROR_CODES = [423, 460, 403, 503]
  const status = err.response?.status

  const state = getState()
  const billmgrIsLocked = selectors.getBillmgrIsLocked(state)
  const unlockBillmgr = () => {
    if (billmgrIsLocked) {
      dispatch(actions.setBillmgrIsLocked(false))
      dispatch(actions.setLockTime(''))
    }
  }

  /** Network errors handling */
  if (NETWORK_ERROR_CODES.includes(status)) {
    if (status === 423 || status === 503) {
      const lockTimeText = err.response.data

      dispatch(actions.setBillmgrIsLocked(true))
      lockTimeText && dispatch(actions.setLockTime(lockTimeText))
    } else if (status === 460 || status === 403) {
      dispatch(authActions.setAuthErrorMsg(`warnings.${status}_error_code`))
      cookies.eraseCookie('sessionId')
      dispatch(authActions.logoutSuccess())
      unlockBillmgr()
    }

    return Promise.reject(new Error(ERROR_ALREADY_HANDLED))

    /** Request was canceled */
  } else if (err?.code === 'ERR_CANCELED' && !err.response) {
    return Promise.reject(new Error(ERROR_ALREADY_HANDLED))
  } else if (err?.code === 'ERR_NETWORK' && !err.response) {
    await fetch('https://one.one.one.one/', { mode: 'no-cors' })
      /**  Internet connection is available - throwing error farther */
      .then(() => {
        !onlineStatus && dispatch(actions.setOnline())
        toast.error(
          `A network error has happened ${status ? 'with a status code of ' + status : ''}, you can contact our support ${SUPPORT_MAIL}`,
          {
            toastId: 'network_error',
          },
        )
      })
      /** Network Error, internet disconnected */
      .catch(() => {
        onlineStatus && dispatch(actions.setOffline())
      })

    return Promise.reject(new Error(ERROR_ALREADY_HANDLED))
    /** If network error is unknown - throwing error farther */
  } else {
    unlockBillmgr()

    return Promise.reject(err)
  }
}
