const SvgComponent = props => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.985 9.189c1.125 0 2.1-.404 2.895-1.2a3.962 3.962 0 0 0 1.2-2.895 3.962 3.962 0 0 0-1.2-2.895A3.963 3.963 0 0 0 9.985 1C8.86 1 7.886 1.403 7.09 2.2a3.962 3.962 0 0 0-1.2 2.894c0 1.125.404 2.1 1.2 2.895.796.796 1.77 1.2 2.895 1.2ZM17.149 14.071c-.023-.33-.07-.692-.138-1.074a8.475 8.475 0 0 0-.264-1.08 5.337 5.337 0 0 0-.444-1.007 3.8 3.8 0 0 0-.67-.873 2.953 2.953 0 0 0-.961-.604 3.324 3.324 0 0 0-1.228-.222c-.174 0-.341.071-.666.282-.199.13-.432.28-.693.447a3.972 3.972 0 0 1-.897.395 3.56 3.56 0 0 1-1.097.178c-.364 0-.733-.06-1.097-.178a3.96 3.96 0 0 1-.896-.395 94.984 94.984 0 0 1-.694-.447c-.324-.21-.492-.282-.666-.282a3.32 3.32 0 0 0-1.227.222 2.95 2.95 0 0 0-.962.605 3.802 3.802 0 0 0-.67.872 5.345 5.345 0 0 0-.443 1.007 8.481 8.481 0 0 0-.264 1.08c-.069.381-.115.743-.138 1.075A14.57 14.57 0 0 0 3 15.076c0 .887.282 1.605.838 2.135.55.523 1.277.788 2.16.788h8.186c.884 0 1.611-.265 2.16-.788.557-.53.84-1.248.84-2.135 0-.343-.012-.68-.035-1.005Z"
      fill="#AE9CCD"
    />
  </svg>
)

export default SvgComponent
