import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={darkTheme ? '#decbfe' : '#ae9ccd'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0183 7.67016C17.6913 7.67716 18.6263 7.68016 19.4203 7.67716C19.8263 7.67616 20.0333 7.18516 19.7513 6.89016C19.2423 6.35616 18.5333 5.61016 17.8203 4.86216C17.1053 4.11116 16.3873 3.35616 15.8643 2.80816C15.5763 2.50416 15.0713 2.71316 15.0713 3.13316V5.70316C15.0713 6.78216 15.9503 7.67016 17.0183 7.67016Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8595 13.811H8.13153C7.71753 13.811 7.38152 13.475 7.38152 13.061C7.38152 12.647 7.71753 12.311 8.13153 12.311H14.8595C15.2735 12.311 15.6095 12.647 15.6095 13.061C15.6095 13.475 15.2735 13.811 14.8595 13.811ZM12.5905 17.232H8.13153C7.71753 17.232 7.38152 16.896 7.38152 16.482C7.38152 16.068 7.71753 15.732 8.13153 15.732H12.5905C13.0055 15.732 13.3405 16.068 13.3405 16.482C13.3405 16.896 13.0055 17.232 12.5905 17.232ZM8.13153 8.891H11.0745C11.4885 8.891 11.8245 9.227 11.8245 9.641C11.8245 10.055 11.4885 10.391 11.0745 10.391H8.13153C7.71753 10.391 7.38152 10.055 7.38152 9.641C7.38152 9.227 7.71753 8.891 8.13153 8.891ZM19.8535 9.094H16.8895C15.1235 9.085 13.6655 7.622 13.6655 5.838V2.698C13.6655 2.455 13.4725 2.25 13.2215 2.25H8.06652C5.66252 2.25 3.71252 4.229 3.71252 6.647V17.158C3.71252 19.693 5.75952 21.75 8.26952 21.75H15.9435C18.3475 21.75 20.2875 19.79 20.2875 17.372V9.533C20.2875 9.289 20.0945 9.094 19.8535 9.094Z"
      />
    </svg>
  )
}
