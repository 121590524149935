export default function SvgComponent(props) {
  return (
    <svg
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.966 13.337H1.3c-.345 0-.675-.139-.919-.386a1.327 1.327 0 0 1-.381-.93V1.317C0 .967.137.633.381.386S.955 0 1.3 0h8.666c.344 0 .675.14.918.386.244.247.381.581.382.93v10.705c0 .349-.138.683-.382.93a1.293 1.293 0 0 1-.918.386Z" />
      <path d="M13.506 2.663h-1.048a.474.474 0 0 0-.092.008v10.462c0 .35-.138.684-.382.93a1.293 1.293 0 0 1-.92.386h-7.47a.524.524 0 0 0-.053.234c0 .35.137.684.38.93.244.248.575.386.92.387h8.665c.345 0 .676-.139.92-.386.243-.247.38-.582.38-.93V3.978c0-.349-.137-.684-.38-.93a1.292 1.292 0 0 0-.92-.386Z" />
    </svg>
  )
}
