import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={darkTheme ? '#decbfe' : '#ae9ccd'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5004 8.442C21.5004 8.60769 21.3661 8.742 21.2004 8.742H2.80049C2.63481 8.742 2.50049 8.60769 2.50049 8.442V8.026C2.50049 4.721 4.62349 2.5 7.78349 2.5H16.2174C19.3774 2.5 21.5004 4.721 21.5004 8.026V8.442ZM7.04449 6.551C7.04449 6.137 6.70349 5.801 6.28949 5.801C5.87549 5.801 5.53949 6.137 5.53949 6.551C5.53949 6.965 5.87549 7.301 6.28949 7.301H6.29949C6.71349 7.301 7.04449 6.965 7.04449 6.551ZM9.53049 6.551C9.53049 6.137 9.18949 5.801 8.77549 5.801C8.36149 5.801 8.02549 6.137 8.02549 6.551C8.02549 6.965 8.36149 7.301 8.77549 7.301H8.78549C9.19949 7.301 9.53049 6.965 9.53049 6.551ZM12.0174 6.551C12.0174 6.137 11.6754 5.801 11.2614 5.801C10.8474 5.801 10.5114 6.137 10.5114 6.551C10.5114 6.965 10.8474 7.301 11.2614 7.301H11.2714C11.6854 7.301 12.0174 6.965 12.0174 6.551Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 10.5422C2.5 10.3765 2.63431 10.2422 2.8 10.2422H21.2C21.3656 10.2422 21.5 10.3765 21.5 10.5422V15.9732C21.5 19.2792 19.377 21.5002 16.217 21.5002H7.784C4.624 21.5002 2.5 19.2792 2.5 15.9732V10.5422ZM12.743 17.9592H15.479C15.893 17.9592 16.229 17.6232 16.229 17.2092C16.229 16.7952 15.893 16.4592 15.479 16.4592H12.743C12.329 16.4592 11.993 16.7952 11.993 17.2092C11.993 17.6232 12.329 17.9592 12.743 17.9592ZM9.054 17.2632L11.018 15.2992C11.311 15.0062 11.311 14.5322 11.018 14.2392L9.054 12.2752C8.761 11.9822 8.287 11.9822 7.994 12.2752C7.701 12.5682 7.701 13.0422 7.994 13.3352L9.427 14.7692L7.994 16.2032C7.701 16.4962 7.701 16.9702 7.994 17.2632C8.14 17.4102 8.332 17.4832 8.524 17.4832C8.716 17.4832 8.908 17.4102 9.054 17.2632Z"
      />
    </svg>
  )
}
