import { useEffect, useReducer } from 'react'
import s from './PushNotifications.module.scss'
import NotificationItem from './NotificationItem'
import { useTranslation } from 'react-i18next'

const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [...state, ...action.payload]
    case 'remove':
      return state.filter(el => el.$id !== action.payload)
    case 'clear':
      return []
    default:
      return state
  }
}

export default function PushNotifications({ notifications }) {
  const [newNotifications, setNewNotifications] = useReducer(reducer, [])
  const { t } = useTranslation(['cart'])

  useEffect(() => {
    const newMessages = notifications.messages?.filter(el => el.$new)

    if (newMessages?.length) {
      setNewNotifications({ type: 'add', payload: newMessages })
    }
  }, [notifications])

  const hideAllHandler = () => setNewNotifications({ type: 'clear' })

  return (
    <div className={s.list_wrapper}>
      <ul className={s.list}>
        {newNotifications.map(({ $id, msg }) => (
          <NotificationItem
            message={msg.$}
            key={$id}
            onClick={() => setNewNotifications({ type: 'remove', payload: $id })}
          />
        ))}
      </ul>
      {newNotifications.length > 1 && (
        <button className={s.hide_all} onClick={hideAllHandler}>
          {t('Hide')}
        </button>
      )}
    </div>
  )
}
