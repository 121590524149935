export default function SvgComponent(props) {
  return (
    <svg width={21} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m2.262 8.174 1.572 1.575 7.072 7.09a3.328 3.328 0 0 0 4.706-.008 3.348 3.348 0 0 0 .009-4.718L8.549 5.02a.74.74 0 0 0-1.048 0c-.29.29-.29.76 0 1.05l7.072 7.092a1.86 1.86 0 0 1 0 2.626 1.849 1.849 0 0 1-2.62 0L4.88 8.699 3.308 7.123a3.35 3.35 0 0 1 .001-4.728 3.329 3.329 0 0 1 4.716.001l1.31 1.314 7.333 7.352.524.526a4.837 4.837 0 0 1-.052 6.775 4.807 4.807 0 0 1-6.758.052l-7.858-7.878a.74.74 0 0 0-1.24.333.744.744 0 0 0 .193.718l7.857 7.877a6.286 6.286 0 0 0 8.896-.01 6.324 6.324 0 0 0 .01-8.918l-7.858-7.878-1.311-1.313a4.807 4.807 0 0 0-6.742.068 4.837 4.837 0 0 0-.067 6.76Z"
        fill="#B3A9C1"
      />
    </svg>
  )
}
