export default function SvgComponent({ width = 30, height = 30, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_11867_28817)">
        <path
          d="M17.4487 7.99059L10.0523 7.99023C9.72568 7.99023 9.46094 8.25494 9.46094 8.58155V10.9444C9.46094 11.2709 9.72568 11.5357 10.0523 11.5357H14.2175C13.7614 12.7194 12.9101 13.7106 11.824 14.3405L13.6 17.415C16.449 15.7673 18.1334 12.8762 18.1334 9.63994C18.1334 9.17914 18.0994 8.84973 18.0315 8.47881C17.9798 8.197 17.7352 7.99059 17.4487 7.99059Z"
          fill="#167EE6"
        />
        <path
          d="M9.06669 15.086C7.02832 15.086 5.24885 13.9723 4.29313 12.3242L1.21875 14.0963C2.78328 16.8078 5.71415 18.6338 9.06669 18.6338C10.7113 18.6338 12.2632 18.191 13.6 17.4193V17.4151L11.824 14.3406C11.0116 14.8117 10.0715 15.086 9.06669 15.086Z"
          fill="#12B347"
        />
        <path
          d="M13.6036 17.4186V17.4144L11.8276 14.3398C11.0152 14.811 10.0752 15.0853 9.07031 15.0853V18.6331C10.715 18.6331 12.2669 18.1903 13.6036 17.4186Z"
          fill="#0F993E"
        />
        <path
          d="M3.54783 9.56623C3.54783 8.56149 3.82203 7.6215 4.2931 6.80915L1.21872 5.03711C0.442779 6.36973 0 7.91737 0 9.56623C0 11.2151 0.442779 12.7627 1.21872 14.0953L4.2931 12.3233C3.82203 11.511 3.54783 10.571 3.54783 9.56623Z"
          fill="#FFD500"
        />
        <path
          d="M9.06669 4.04783C10.3959 4.04783 11.6169 4.52015 12.5705 5.30579C12.8058 5.49959 13.1478 5.4856 13.3633 5.27009L15.0374 3.59595C15.2819 3.35143 15.2645 2.95119 15.0033 2.72459C13.4055 1.33842 11.3265 0.5 9.06669 0.5C5.71415 0.5 2.78328 2.32598 1.21875 5.03755L4.29313 6.80959C5.24885 5.16154 7.02832 4.04783 9.06669 4.04783Z"
          fill="#FF4B26"
        />
        <path
          d="M12.5742 5.30579C12.8094 5.49959 13.1514 5.4856 13.3669 5.27009L15.041 3.59595C15.2855 3.35143 15.2681 2.95119 15.0069 2.72459C13.4091 1.33838 11.3301 0.5 9.07031 0.5V4.04783C10.3995 4.04783 11.6205 4.52015 12.5742 5.30579Z"
          fill="#D93F21"
        />
      </g>
      <defs>
        <clipPath id="clip0_11867_28817">
          <rect
            width="18.1333"
            height="18.1333"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
