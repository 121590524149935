import { useTranslation } from 'react-i18next'
import { Icon } from '@components'
import s from './ErrorPage.module.scss'

export default function ErrorPage() {
  const { t } = useTranslation('other')

  return (
    <div className={s.error_page}>
      <p className={s.error_type}>404</p>
      <p className={s.error_title}>{t('error_type').toUpperCase()}</p>
      {/* <p className={s.error_text}>{t(errorText)}</p> */}

      <Icon name="Error_404" className={s.error_img} svgwidth="200" svgheight="141" />
    </div>
  )
}
