export default function SvgComponent({ color = '#45A884', ...props }) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="14" cy="14" r="13" stroke={color} strokeWidth="2" />
      <path
        d="M11.9094 20C11.609 20 11.4086 19.9006 11.2083 19.7018L6.30048 14.5339C5.89984 14.1364 5.89984 13.5401 6.30048 13.1425C6.70112 12.745 7.30208 12.745 7.70272 13.1425L11.8093 17.5154L20.2227 8.37213C20.5232 7.97459 21.2243 7.87521 21.625 8.17336C22.0256 8.47151 22.1258 9.16719 21.8253 9.56473L21.7251 9.66411L12.6106 19.6025C12.5104 19.9006 12.2099 20 11.9094 20Z"
        fill={color}
      />
    </svg>
  )
}
