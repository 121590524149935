import { useMediaQuery } from 'react-responsive'
import s from './InstancesList.module.scss'
import InstanceItem from './InstanceItem'
import InstanceItemMobile from './InstanceItemMobile'
import no_vds from '@images/services/no_vds.png'
import { useTranslation } from 'react-i18next'

export const HEAD_CELLS = [
  { label: 'Name', value: 'servername' },
  { label: 'Flavor', value: 'pricelist' },
  { label: 'Price', value: 'cost' },
  { label: 'Region', value: 'datacentername' },
  { label: 'Created at', value: 'createdate' },
  { label: 'paid_until', value: 'real_expiredate' },
  { label: 'OS', value: 'instances_os' },
  { label: 'Access IP', value: 'ip' },
]

export default function InstancesList({ instances, editInstance, isFiltered }) {
  const { t } = useTranslation(['cloud_vps', 'vds', 'other'])
  const widerThan768 = useMediaQuery({ query: '(min-width: 768px)' })

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return (
        <th key={cell.label} className={s.th}>
          {t(cell.label)}
        </th>
      )
    })

  if (!instances?.length) {
    return (
      <div className={s.no_vds_wrapper}>
        <img className={s.no_vds} src={no_vds} alt="no_vds" />
        <p className={s.no_vds_title}>
          {t(isFiltered ? 'no_servers_yet_filtered' : 'no_servers_yet', { ns: 'vds' })}
        </p>
      </div>
    )
  }

  return (
    <>
      {widerThan768 ? (
        <table className={s.table}>
          <thead className={s.thead}>
            <tr className={s.tr}>
              {/* <th className={s.th}>
                <CheckBox />
              </th> */}
              {renderHeadCells()}
              <th className={s.th}></th>
            </tr>
          </thead>
          <tbody className={s.tbody}>
            {instances.map(item => {
              return (
                <InstanceItem key={item.id.$} item={item} editInstance={editInstance} />
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className={s.mobile__list}>
          {instances.map(item => {
            return (
              <InstanceItemMobile
                key={item.id.$}
                item={item}
                editInstance={editInstance}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
