import { useEffect, useState } from 'react'
import cn from 'classnames'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { supportOperations } from '@redux'
import { Icon, ImageModal } from '@components'
import s from './MessageItem.module.scss'
import MessageRate from './MessageRate'
import { checkIsImageFile, decodeHTMLEntities } from '@utils'
export default function Component(props) {
  const dispatch = useDispatch()
  const params = useParams()

  const { message } = props

  const downloadFileHandler = (
    fileName,
    fileId,
    setImg = null,
    setImgIsOpened = null,
  ) => {
    dispatch(supportOperations.getFile(fileName, fileId, setImg, setImgIsOpened))
  }

  const setImageHandler = (id, img) => setImages(prev => [...prev, { id, img }])

  useEffect(() => {
    message?.file?.map(el => {
      downloadFileHandler(
        el?.name?.$,
        el?.param?.$,
        img => setImageHandler(el.param.$, img),
        () => {},
      )
    })
  }, [])

  const [images, setImages] = useState([])
  const [imageIsOpened, setImageIsOpened] = useState(false)

  const closeImageHandler = () => {
    setImageIsOpened(false)
  }

  return (
    <div
      className={cn(
        s.messageContainer,
        message?.$type === 'outcoming' ? s.outcoming : s.incoming,
      )}
    >
      <img
        className={s.avatar}
        src={`${process.env.REACT_APP_BASE_URL}${message.avatar?.$}`}
        alt={message.avatar?.$name}
      />

      <div
        className={cn(
          s.messageBlock,
          message?.$type === 'outcoming' ? s.outcoming : s.incoming,
        )}
      >
        <div className={s.headerWithPhoto}>
          <img
            className={s.avatarSmall}
            src={`${process.env.REACT_APP_BASE_URL}${message.avatar?.$}`}
            alt={message.avatar?.$name}
          />
          <div className={s.messageHeader}>
            <div className={s.userInfo}>
              <span>{message?.user?.realname?.$}</span>
            </div>
            <div className={s.datetime}>
              {dayjs(message?.date_post?.$).format('DD MMMM YYYY HH:mm')}
            </div>
          </div>
        </div>
        <div className={s.messageBody}>
          <div className={s.messageText}>{decodeHTMLEntities(message?.body?.$)}</div>
          {message?.file && (
            <div className={s.fileBlock}>
              {message?.file?.map(el => {
                const isImg = checkIsImageFile(el?.name?.$)
                const img = images.find(img => img.id === el.param.$)?.img
                const isOpen = () => {
                  if (isImg) {
                    return setImageIsOpened({ img, name: el?.name?.$ })
                  }
                  return downloadFileHandler(el?.name?.$, el?.param?.$)
                }
                return (
                  <button
                    onClick={isOpen}
                    className={cn(s.file, { [s.file_img]: isImg })}
                    key={el?.param?.$}
                  >
                    {isImg ? (
                      <img src={img} alt={el?.name?.$} className={s.img_thumbnail} />
                    ) : (
                      <>
                        <span>{el?.name?.$}</span>
                        <Icon name="Download" />
                      </>
                    )}
                  </button>
                )
              })}
            </div>
          )}
        </div>
        {message?.rates && (
          <MessageRate
            postId={params?.id}
            messageId={message?.$id}
            rateStatus={message?.rates?.rate?.$name}
          />
        )}
      </div>
      <ImageModal imageIsOpened={imageIsOpened} closeImageHandler={closeImageHandler} />
    </div>
  )
}

Component.propTypes = {
  message: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.object]),
}

Component.defaultProps = {
  message: null,
}
