export default function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="#64B998"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.062 2.938a9.954 9.954 0 0 0-14.124 0 9.954 9.954 0 0 0 0 14.124 9.954 9.954 0 0 0 14.124 0 9.954 9.954 0 0 0 0-14.124ZM9.414 5.313a.586.586 0 0 1 1.172 0v3.125a.586.586 0 0 1-1.172 0V5.313ZM10 14.335A4.34 4.34 0 0 1 5.665 10c0-1.25.54-2.44 1.483-3.265a.586.586 0 0 1 .77.882A3.165 3.165 0 0 0 10 13.163 3.167 3.167 0 0 0 13.164 10c0-.904-.389-1.767-1.065-2.367a.586.586 0 0 1 .777-.876A4.34 4.34 0 0 1 14.337 10 4.34 4.34 0 0 1 10 14.335Z" />
    </svg>
  )
}
