import axios from 'axios'
import { applyInterceptors } from './axiosInterceptors'

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

applyInterceptors(axiosInstance)
