export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="#AE9CCD"
      {...props}
    >
      <path d="M12.047 21.3758C6.89307 21.3758 2.69775 17.1828 2.69775 12.0289C2.69775 6.875 6.89072 2.67969 12.047 2.67969C17.2009 2.67969 21.3938 6.87266 21.3938 12.0289C21.3938 17.1828 17.2009 21.3758 12.047 21.3758ZM12.047 4.93437C8.13525 4.93437 4.95244 8.11719 4.95244 12.0289C4.95244 15.9406 8.13525 19.1234 12.047 19.1234C15.9587 19.1234 19.1415 15.9406 19.1415 12.0289C19.1415 8.11719 15.9587 4.93437 12.047 4.93437Z" />
      <path d="M10.2443 8.1074L16.1014 11.4894C16.5162 11.7285 16.5162 12.3262 16.1014 12.5652L10.2443 15.9496C9.82949 16.1887 9.31152 15.8887 9.31152 15.4105V8.64647C9.31152 8.16834 9.82949 7.86834 10.2443 8.1074Z" />
    </svg>
  )
}
