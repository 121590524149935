import { CHECK_IPv6_URL } from '@utils/constants'

export default function checkIPv6({
  setIsIPv6Supported,
  setIsLoading,
  setIsIPv6RequestError,
}) {
  setIsLoading(true)
  fetch(CHECK_IPv6_URL)
    .then(resp => resp.json())
    .then(data => {
      setIsIPv6Supported(data.ip.includes(':'))
      setIsLoading(false)
      setIsIPv6RequestError(false)
    })
    .catch(() => {
      setIsIPv6Supported(false)
      setIsLoading(false)
      setIsIPv6RequestError(true)
    })
}
