import { Button, Modal, PageTabBar, Select } from '@components'
import s from './ProlongServiceModal.module.scss'
import { useTranslation } from 'react-i18next'
import { translatePeriod } from '@utils'
import { useEffect, useReducer, useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { billingOperations, cartActions } from '@redux'

const PROLONG_TYPES = {
  manual: 'manual',
  auto: 'auto',
}

export default function ProlongServiceModal({ item, closeModal, service, getItems }) {
  const { t } = useTranslation(['autoprolong'])
  const dispatch = useDispatch()

  const [manualState, setManualState] = useReducer((state, action) => {
    return { ...state, ...action }
  }, {})

  const [autoState, setAutoState] = useReducer((state, action) => {
    return { ...state, ...action }
  }, {})

  const [activeTab, setActiveTab] = useState(PROLONG_TYPES.manual)
  const isManual = activeTab === PROLONG_TYPES.manual

  const manualProlong = ({ values, successCallback }) =>
    dispatch(
      billingOperations.manualProlongItem({
        values: { elid: item.id.$, ...values },
        successCallback: successCallback,
      }),
    )

  const autoProlong = ({ values, successCallback }) => {
    dispatch(
      billingOperations.autoProlongItem({
        values: { elid: item.id.$, ...values },
        service,
        successCallback,
      }),
    )
  }

  useEffect(() => {
    manualProlong({ successCallback: getManualProlongData })
  }, [])

  useEffect(() => {
    if (!isManual && !autoState.periodList?.length) {
      autoProlong({ successCallback: getAutoProlongData })
    }
  }, [activeTab])

  const getPeriodList = (data, key) =>
    data.slist
      ?.find(el => el.$name === key)
      ?.val?.map(el => ({ value: el.$key, label: translatePeriod(el?.$, el.$key, t) }))

  const getStoredMethodList = data =>
    data.slist
      ?.find(el => el.$name === 'stored_method')
      ?.val?.map(el => ({
        value: el.$key,
        label: t(el.$?.trim()),
      }))

  const getAutoProlongData = data => {
    const periodList = getPeriodList(data, 'autoprolong')
    const storedMethodList = getStoredMethodList(data)

    setAutoState({
      periodList,
      period: data.autoprolong?.$,
      storedMethodList,
      storedMethod: data.stored_method?.$,
    })
  }

  const getManualProlongData = data => {
    const periodList = getPeriodList(data, 'period')

    setManualState({
      periodList,
      newExpireDate: data.newexpiredate?.$ + ' ' + data.expire_time?.$,
      period: data.period?.$,
    })
  }

  const navSections = [
    {
      label: t('manual_prolong'),
      allowToRender: true,
      localValue: PROLONG_TYPES.manual,
      onLocalClick: () => {
        setActiveTab(PROLONG_TYPES.manual)
      },
    },
    {
      label: t('autoprolong'),
      allowToRender: true,
      localValue: PROLONG_TYPES.auto,
      onLocalClick: () => {
        setActiveTab(PROLONG_TYPES.auto)
      },
    },
  ]

  const onSubmitHandler = values => {
    const { period, ...autoprolongValues } = values

    isManual
      ? manualProlong({
          values: { period, clicked_button: 'basket', sok: 'ok', lang: 'en' },
          successCallback: () => {
            closeModal()

            dispatch(
              cartActions.setCartIsOpenedState({
                isOpened: true,
                redirectPath: window.location.pathname,
                manualAutoprolong: true,
              }),
            )
          },
        })
      : autoProlong({
          values: { ...autoprolongValues, clicked_button: 'ok', sok: 'ok' },
          successCallback: () => {
            getItems()
            closeModal()
          },
        })
  }

  return (
    <Modal
      isOpen={!!item && !!manualState.period}
      closeModal={closeModal}
      className={s.prolong_modal}
    >
      <Modal.Header>
        <p>{t('server_renew_settings_title')}</p>
      </Modal.Header>
      <Modal.Body className={s.prolong_modal_body}>
        <Formik
          enableReinitialize
          initialValues={{
            period: manualState.period,
            autoprolong: autoState.period,
            stored_method: autoState.storedMethod,
          }}
          onSubmit={onSubmitHandler}
        >
          {({ values }) => {
            const expireTime = item.i_expiretime?.$ || item.expiretime?.$
            return (
              <Form id={'prolong'} className={s.body}>
                <div className={s.prolong_info}>
                  <p>{t('active_until')}:</p>
                  <p>
                    {item.real_expiredate?.$} {expireTime?.slice(0, 5)}
                  </p>
                  <p>{t('autoprolong')}:</p>
                  <p>
                    {item.autoprolong?.$ && item.autoprolong?.$ !== 'null'
                      ? t(`autoprolong_period_info.${item.autoprolong?.$.toLowerCase()}`)
                      : t('Disabled')}
                  </p>
                </div>
                <PageTabBar sections={navSections} activeValue={activeTab} />
                {isManual ? (
                  <>
                    <Select
                      label={`${t('period')}:`}
                      itemsList={manualState.periodList}
                      value={manualState.period}
                      getElement={period => {
                        manualProlong({
                          values: { sv_field: 'period', period },
                          successCallback: data => {
                            setManualState({
                              newExpireDate:
                                data.newexpiredate?.$ + ' ' + data.expire_time?.$,
                              period,
                            })
                          },
                        })
                      }}
                      isShadow
                    />
                    <p>
                      {t('extended_until')}: {manualState.newExpireDate}
                    </p>
                  </>
                ) : (
                  <>
                    {item.autoprolong?.$ &&
                      item.autoprolong?.$ !== 'null' &&
                      values.autoprolong !== 'null' && (
                        <button
                          type="button"
                          className={s.cancel_autoprolong}
                          onClick={() => {
                            autoProlong({
                              values: { sv_field: 'autoprolong', autoprolong: 'null' },
                              successCallback: data => {
                                const storedMethodList = getStoredMethodList(data)
                                setAutoState({
                                  period: 'null',
                                  storedMethodList,
                                  storedMethod: data.stored_method?.$,
                                })
                              },
                            })
                          }}
                        >
                          {t('cancel_autoprolong')}
                        </button>
                      )}
                    <Select
                      label={`${t('period_autoprolong')}:`}
                      itemsList={autoState.periodList}
                      value={autoState.period}
                      getElement={period => {
                        autoProlong({
                          values: { sv_field: 'autoprolong', autoprolong: period },
                          successCallback: data => {
                            const storedMethodList = getStoredMethodList(data)
                            setAutoState({
                              period,
                              storedMethodList,
                              storedMethod: data.stored_method?.$,
                            })
                          },
                        })
                      }}
                      isShadow
                    />
                    <Select
                      label={`${t('payment_method')}:`}
                      placeholder={`${t('payment_method')}`}
                      itemsList={autoState.storedMethodList}
                      value={autoState.storedMethod}
                      getElement={storedMethod => {
                        setAutoState({ storedMethod })
                      }}
                      disabled={values?.autoprolong === 'null'}
                      isShadow
                    />
                    {values?.autoprolong && values?.autoprolong !== 'null' && (
                      <>
                        <p>
                          {t('next_prolong_date', {
                            date: item.real_expiredate?.$ + ' ' + expireTime.slice(0, 5),
                          })}
                        </p>
                      </>
                    )}
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer column>
        <Button
          label={t(isManual ? 'to_pay' : 'save')}
          size={'large'}
          type="submit"
          form={'prolong'}
          isShadow
        />
        <button type="button" onClick={closeModal}>
          {t('cancel')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
