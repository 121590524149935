export default function SvgComponent({
  colorFirst = '#FA4BB4',
  colorSecond = '#ED32CF',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_10840_28543)">
        <path
          d="M7.34203 14.9071C7.29334 14.9071 7.24517 14.897 7.20056 14.8775C7.15596 14.858 7.11587 14.8294 7.08282 14.7937L0.0937854 7.23354C0.0471947 7.18314 0.016308 7.12025 0.00490551 7.05256C-0.00649701 6.98488 0.0020792 6.91534 0.0295846 6.85246C0.0570901 6.78957 0.102332 6.73607 0.159772 6.6985C0.217212 6.66093 0.28436 6.64092 0.352997 6.64092H3.71713C3.76764 6.64092 3.81756 6.65176 3.86353 6.6727C3.90949 6.69365 3.95043 6.7242 3.98358 6.76231L6.31934 9.44953C6.57177 8.90992 7.06044 8.01146 7.91796 6.91665C9.18567 5.29812 11.5437 2.91777 15.5779 0.768964C15.6559 0.727441 15.7466 0.716664 15.8322 0.738761C15.9177 0.760859 15.9918 0.814224 16.0399 0.88832C16.0881 0.962416 16.1066 1.05186 16.092 1.13898C16.0774 1.2261 16.0306 1.30458 15.961 1.35891C15.9456 1.37094 14.3901 2.59587 12.5999 4.83953C10.9524 6.90426 8.76227 10.2804 7.68459 14.6389C7.66566 14.7155 7.62162 14.7835 7.55952 14.8322C7.49741 14.8808 7.4208 14.9072 7.34192 14.9072L7.34203 14.9071Z"
          fill="url(#paint0_linear_10840_28543)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10840_28543"
          x1="4.11865e-08"
          y1="7.81738"
          x2="16.0968"
          y2="7.85922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorFirst} />
          <stop offset="0.982292" stopColor={colorSecond} />
        </linearGradient>
        <clipPath id="clip0_10840_28543">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
