import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={darkTheme ? '#fff' : '#AE9CCD'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43987 4.96484C6.75054 4.96484 7.00237 5.21668 7.00237 5.52734V12.8966L8.22395 11.6751C8.44362 11.4554 8.7998 11.4554 9.01947 11.6751C9.23907 11.8947 9.23907 12.2508 9.01947 12.4705L6.83762 14.6524C6.61795 14.872 6.2618 14.872 6.04212 14.6524L3.86031 12.4705C3.64064 12.2508 3.64064 11.8947 3.86031 11.6751C4.07998 11.4554 4.43613 11.4554 4.6558 11.6751L5.87737 12.8966V5.52734C5.87737 5.21668 6.12921 4.96484 6.43987 4.96484Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6526 11.7119C11.6526 11.4012 11.9044 11.1494 12.2151 11.1494H15.1242C15.3316 11.1494 15.5222 11.2636 15.6202 11.4465C15.718 11.6294 15.7073 11.8513 15.5922 12.0239L13.2661 15.513H15.1242C15.4348 15.513 15.6867 15.7649 15.6867 16.0755C15.6867 16.3862 15.4348 16.638 15.1242 16.638H12.2151C12.0076 16.638 11.8171 16.5239 11.7192 16.341C11.6212 16.158 11.632 15.9361 11.7471 15.7635L14.0731 12.2744H12.2151C11.9044 12.2744 11.6526 12.0225 11.6526 11.7119Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7822 7.25586C11.7822 6.9452 12.0341 6.69336 12.3447 6.69336H14.9964C15.307 6.69336 15.5589 6.9452 15.5589 7.25586C15.5589 7.56652 15.307 7.81836 14.9964 7.81836H12.3447C12.0341 7.81836 11.7822 7.56652 11.7822 7.25586Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6706 3.51172C13.8976 3.51174 14.1023 3.64815 14.1897 3.85761L16.0108 8.222C16.1305 8.50865 15.995 8.83805 15.7083 8.95768C15.4216 9.0773 15.0922 8.94193 14.9725 8.6552L13.6705 5.53469L12.3679 8.65528C12.2482 8.94193 11.9188 9.07738 11.6321 8.95768C11.3454 8.83798 11.21 8.50858 11.3297 8.22193L13.1515 3.85753C13.2389 3.64809 13.4437 3.5117 13.6706 3.51172Z"
      />
    </svg>
  )
}
