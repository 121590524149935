const SvgComponent = props => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.987 7.332a2.082 2.082 0 0 1-1.642-2.816.977.977 0 0 0-.292-1.1 7.836 7.836 0 0 0-2.338-1.354.983.983 0 0 0-1.11.308 2.077 2.077 0 0 1-3.272 0 .983.983 0 0 0-1.11-.308 7.873 7.873 0 0 0-2.179 1.221.988.988 0 0 0-.308 1.132 2.072 2.072 0 0 1-1.727 2.847.983.983 0 0 0-.86.792A7.607 7.607 0 0 0 2.1 10.79a.983.983 0 0 0 .866.823 2.072 2.072 0 0 1 1.69 2.928.967.967 0 0 0 .27 1.158 7.894 7.894 0 0 0 2.316 1.333.978.978 0 0 0 1.132-.356 2.055 2.055 0 0 1 1.7-.892 2.083 2.083 0 0 1 1.668.84.978.978 0 0 0 1.148.323 7.966 7.966 0 0 0 2.125-1.27.982.982 0 0 0 .287-1.12 2.072 2.072 0 0 1 1.662-2.864.983.983 0 0 0 .834-.807c.086-.443.132-.893.139-1.344 0-.476-.045-.951-.133-1.419a.972.972 0 0 0-.818-.791Zm-4.362 2.21a2.657 2.657 0 1 1-5.313 0 2.657 2.657 0 0 1 5.313 0Z"
      fill="#AE9CCD"
    />
  </svg>
)

export default SvgComponent
