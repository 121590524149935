import { Modal } from '@components'
import s from './ImageModal.module.scss'

export default function ImageModal({ imageIsOpened, closeImageHandler }) {
  return (
    <Modal
      isOpen={Boolean(imageIsOpened)}
      closeModal={closeImageHandler}
      className={s.modal}
      isClickOutside
    >
      <Modal.Header className={s.modal__header} />
      <Modal.Body className={s.modal__body}>
        <img src={imageIsOpened.img} alt={imageIsOpened.name} />
      </Modal.Body>
    </Modal>
  )
}
