const SvgComponent = props => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.014 12.506a5.511 5.511 0 0 0-3.025-4.908c-.057 4.044-3.34 7.324-7.387 7.38a5.517 5.517 0 0 0 4.912 3.023c.99 0 1.953-.264 2.799-.764l2.677.74-.74-2.675a5.47 5.47 0 0 0 .764-2.796Z"
      fill="#AE9CCD"
    />
    <path
      d="M13.994 7.491C13.994 3.911 11.079 1 7.497 1 3.914 1 1 3.912 1 7.491c0 1.167.31 2.302.9 3.3l-.876 3.167 3.17-.876c.999.59 2.135.9 3.303.9 3.582 0 6.497-2.912 6.497-6.49ZM6.5 5.981h-.997c0-1.1.894-1.993 1.994-1.993S9.49 4.882 9.49 5.98c0 .558-.236 1.094-.649 1.47l-.847.775v.777h-.997V7.786l1.171-1.07A.998.998 0 1 0 6.5 5.98Zm.498 4.017h.997v.996h-.997v-.996Z"
      fill="#AE9CCD"
    />
  </svg>
)

export default SvgComponent
