import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 26 22"
      fill={darkTheme ? '#decbfe' : '#ae9ccd'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.612 20.29a.431.431 0 0 1-.033-.225c.01-.06-.028-.11-.116-.15-.089-.038-.133-.068-.133-.09 0-.022-.064-.039-.19-.05l-.192-.017H4.775c-.73 0-1.354-.26-1.874-.78a2.554 2.554 0 0 1-.779-1.873V5.432c0-.73.26-1.354.78-1.873.52-.52 1.144-.78 1.873-.78h5.306a.5.5 0 0 0 .34-.107.55.55 0 0 0 .166-.324 2.77 2.77 0 0 0 .041-.829 7.588 7.588 0 0 1-.016-.331.51.51 0 0 0-.158-.373.51.51 0 0 0-.373-.158H4.775c-1.315 0-2.44.467-3.374 1.401C.467 2.992 0 4.117 0 5.432v11.673c0 1.316.467 2.44 1.401 3.374.934.935 2.059 1.402 3.374 1.402h5.306a.503.503 0 0 0 .34-.108.552.552 0 0 0 .166-.323 2.806 2.806 0 0 0 .042-.829 7.44 7.44 0 0 1-.017-.332Z" />
      <path d="m25.684 10.524-9.02-9.02a1.02 1.02 0 0 0-.746-.316c-.287 0-.536.106-.746.315a1.02 1.02 0 0 0-.315.747v4.775H7.428a1.02 1.02 0 0 0-.746.315 1.02 1.02 0 0 0-.315.746v6.368c0 .287.105.536.315.746.21.21.459.315.746.315h7.429v4.775c0 .288.105.537.315.746.21.21.459.316.746.316.288 0 .536-.105.746-.316l9.02-9.02c.21-.21.315-.458.315-.746a1.02 1.02 0 0 0-.315-.746Z" />
    </svg>
  )
}
