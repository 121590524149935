import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={darkTheme ? '#decbfe' : '#ae9ccd'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.77618 8.04497C8.88135 8.15023 9.04727 8.16172 9.17085 8.07885C9.98362 7.53382 10.9519 7.20622 12.0022 7.20622C13.0525 7.20622 14.0208 7.53381 14.8336 8.07882C14.9572 8.1617 15.1231 8.1502 15.2283 8.04491L17.9331 5.33659C18.0567 5.21289 18.0489 5.00983 17.9123 4.90074C16.2881 3.604 14.235 2.82422 12.0002 2.82422C9.76653 2.82422 7.7135 3.60394 6.09022 4.89972C5.95356 5.0088 5.94584 5.2119 6.06942 5.3356L8.77618 8.04497Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4287 6.41948C19.3196 6.28257 19.1162 6.27488 18.9925 6.39876L16.2892 9.10549C16.184 9.21084 16.1727 9.37689 16.2559 9.50041C16.8015 10.3113 17.129 11.2776 17.129 12.3249C17.129 13.3762 16.8005 14.3455 16.2537 15.1585C16.1707 15.282 16.1819 15.448 16.2871 15.5533L18.9876 18.2573C19.1113 18.3811 19.3146 18.3735 19.4237 18.2367C20.7203 16.6126 21.5 14.5596 21.5 12.3249C21.5 10.0934 20.7223 8.04248 19.4287 6.41948Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71676 15.5543C7.822 15.449 7.8333 15.283 7.7502 15.1595C7.20331 14.3465 6.874 13.3771 6.874 12.325C6.874 11.2776 7.20232 10.3114 7.74808 9.50051C7.83123 9.37697 7.81996 9.21087 7.7147 9.10554L5.00855 6.39765C4.88482 6.27384 4.68146 6.28156 4.57236 6.41843C3.27864 8.04145 2.5 10.0924 2.5 12.325C2.5 14.5607 3.28066 16.6138 4.57831 18.238C4.68749 18.3746 4.89066 18.3822 5.01429 18.2585L7.71676 15.5543Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2271 16.6142C15.1219 16.5088 14.9557 16.4974 14.8321 16.5805C14.0202 17.1262 13.052 17.4537 12.0018 17.4537C10.9526 17.4537 9.98445 17.1261 9.17253 16.5805C9.04892 16.4974 8.88281 16.5088 8.77754 16.6141L6.07519 19.3182C5.95155 19.4419 5.95929 19.6451 6.09605 19.7542C7.71823 21.0478 9.76916 21.8247 11.9998 21.8247C14.2325 21.8247 16.2835 21.0469 17.9066 19.7531C18.0433 19.6441 18.0511 19.4409 17.9275 19.3171L15.2271 16.6142Z"
      />
    </svg>
  )
}
