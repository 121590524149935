export default function SvgComponent(props) {
  return (
    <svg width={20} height={11} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.213 6.994c.663.63 1.363 1.222 1.959 1.915.262.308.511.626.701.983.27.508.026 1.067-.443 1.098l-2.913-.002c-.752.061-1.351-.233-1.855-.732-.404-.4-.777-.825-1.165-1.238a2.737 2.737 0 0 0-.525-.453c-.397-.25-.743-.174-.97.23-.231.41-.284.864-.307 1.32-.03.668-.238.843-.928.873-1.472.068-2.87-.149-4.169-.87-1.145-.637-2.032-1.536-2.805-2.554C2.289 5.584 1.136 3.406.101 1.168-.132.664.038.394.611.384 1.56.366 2.51.367 3.463.383c.387.005.642.22.792.575A17.037 17.037 0 0 0 6.188 4.44c.21.289.425.577.73.78.338.225.595.15.754-.215.101-.232.145-.482.168-.73.075-.855.085-1.708-.047-2.56-.08-.532-.389-.876-.935-.976C6.58.687 6.621.587 6.756.432 6.99.166 7.211 0 7.65 0h3.293c.518.1.633.326.704.833l.003 3.555c-.006.196.1.778.464.908.291.093.483-.134.658-.313.788-.813 1.351-1.774 1.854-2.77.223-.437.415-.892.6-1.346.138-.337.355-.503.745-.495l3.17.003c.093 0 .188 0 .28.016.533.088.68.312.515.819-.26.795-.766 1.457-1.26 2.123-.529.71-1.094 1.397-1.618 2.112-.482.653-.443.982.155 1.55Z"
        fill="#4267B2"
      />
    </svg>
  )
}
