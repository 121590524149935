import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={darkTheme ? '#fff' : '#AE9CCD'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43987 4.96484C6.75053 4.96484 7.00237 5.21668 7.00237 5.52734V12.8966L8.22395 11.6751C8.44362 11.4554 8.7998 11.4554 9.0194 11.6751C9.23907 11.8947 9.23907 12.2508 9.0194 12.4705L6.83762 14.6524C6.61795 14.872 6.26179 14.872 6.04212 14.6524L3.86031 12.4705C3.64064 12.2508 3.64064 11.8947 3.86031 11.6751C4.07998 11.4554 4.43613 11.4554 4.6558 11.6751L5.87737 12.8966V5.52734C5.87737 5.21668 6.12921 4.96484 6.43987 4.96484Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7837 14.8906C11.7837 14.58 12.0355 14.3281 12.3462 14.3281H14.9978C15.3085 14.3281 15.5603 14.58 15.5603 14.8906C15.5603 15.2013 15.3085 15.4531 14.9978 15.4531H12.3462C12.0355 15.4531 11.7837 15.2013 11.7837 14.8906Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6709 11.1465C13.8979 11.1465 14.1025 11.2829 14.19 11.4924L16.0111 15.8567C16.1307 16.1434 15.9952 16.4728 15.7086 16.5925C15.4219 16.7121 15.0925 16.5766 14.9728 16.29L13.6708 13.1695L12.3682 16.2901C12.2485 16.5767 11.9191 16.7121 11.6323 16.5925C11.3457 16.4728 11.2102 16.1434 11.3299 15.8566L13.1518 11.4923C13.2392 11.2828 13.444 11.1465 13.6709 11.1465Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6526 4.07421C11.6526 3.76356 11.9044 3.51171 12.2151 3.51171H15.1242C15.3316 3.51171 15.5222 3.62589 15.6202 3.8088C15.718 3.9917 15.7073 4.21363 15.5922 4.38624L13.2661 7.87535H15.1242C15.4348 7.87535 15.6867 8.1272 15.6867 8.43785C15.6867 8.7485 15.4348 9.00035 15.1242 9.00035H12.2151C12.0076 9.00035 11.8171 8.8862 11.7192 8.70327C11.6212 8.52035 11.632 8.29842 11.7471 8.12585L14.0731 4.63671H12.2151C11.9044 4.63671 11.6526 4.38488 11.6526 4.07421Z"
      />
    </svg>
  )
}
