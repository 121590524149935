const SvgComponent = props => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.844 5.688a5.162 5.162 0 0 0-5.156 5.156A5.162 5.162 0 0 0 10.843 16 5.162 5.162 0 0 0 16 10.844a5.162 5.162 0 0 0-5.156-5.156Zm0 4.687c.775 0 1.406.63 1.406 1.406 0 .61-.393 1.126-.938 1.32v1.024h-.937v-1.024a1.404 1.404 0 0 1-.938-1.32h.938a.47.47 0 1 0 .469-.469c-.776 0-1.406-.63-1.406-1.406 0-.61.393-1.126.937-1.32V7.562h.938v1.024c.544.194.937.71.937 1.32h-.938a.47.47 0 1 0-.468.469Z" />
    <path d="M5.219 5.688c2.891 0 5.156-1.236 5.156-2.813C10.375 1.298 8.11 0 5.219 0 2.327 0 0 1.298 0 2.875s2.328 2.813 5.219 2.813Z" />
    <path d="M0 10.549v.764c0 1.577 2.328 2.812 5.219 2.812.164 0 .323-.015.484-.023a6.053 6.053 0 0 1-.785-1.863c-2.091-.058-3.907-.7-4.918-1.69Z" />
    <path d="M4.773 11.289c-.011-.148-.023-.295-.023-.445 0-.489.064-.962.173-1.417-2.093-.057-3.91-.7-4.923-1.69V8.5c0 1.493 2.102 2.667 4.773 2.789Z" />
    <path d="M5.219 8.5h.001a6.115 6.115 0 0 1 1.317-1.963c-.423.054-.861.088-1.318.088-2.226 0-4.159-.664-5.219-1.701v.764C0 7.265 2.328 8.5 5.219 8.5Z" />
  </svg>
)

export default SvgComponent
