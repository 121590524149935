export default function SvgComponent(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="#AE9CCD"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.47724 16.3474C3.78023 16.0461 4.27149 16.0461 4.57448 16.3474L6 18L6 8C6 4.73367 8.71485 3 12 3C15.2851 3 18 4.73367 18 8V15.8643C18 16.2903 17.4285 17 17 17C16.5715 17 16 16.2903 16 15.8643V8C16 5.58574 14.4281 5 12 5C9.57185 5 8 5.58574 8 8V18L9.68414 16.3474C9.98713 16.0461 10.4783 16.0461 10.7813 16.3474C11.0843 16.6486 11.0843 17.1371 10.7813 17.4383L7.67793 20.5241C7.37494 20.8253 6.88368 20.8253 6.58069 20.5241L3.47724 17.4383C3.17425 17.1371 3.17425 16.6486 3.47724 16.3474Z"
      />
    </svg>
  )
}
