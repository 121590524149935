const SvgComponent = props => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fill="#AE9CCD">
      <path d="M13.521 8.043a5.485 5.485 0 0 0-5.478 5.479A5.485 5.485 0 0 0 13.522 19 5.485 5.485 0 0 0 19 13.521a5.485 5.485 0 0 0-5.479-5.478Zm0 4.98c.824 0 1.495.67 1.495 1.495 0 .648-.418 1.196-.996 1.402v1.088h-.997V15.92a1.492 1.492 0 0 1-.996-1.402h.996a.498.498 0 1 0 .498-.498c-.823 0-1.494-.67-1.494-1.495 0-.648.418-1.196.996-1.402v-1.088h.997v1.088c.578.206.996.754.996 1.402h-.996a.498.498 0 1 0-.498.498ZM7.545 8.043c3.072 0 5.478-1.313 5.478-2.988C13.023 3.379 10.617 2 7.545 2 4.473 2 2 3.38 2 5.055 2 6.73 4.473 8.043 7.545 8.043ZM2 13.209v.812c0 1.675 2.473 2.988 5.545 2.988.174 0 .343-.016.515-.025a6.432 6.432 0 0 1-.835-1.98c-2.222-.06-4.15-.743-5.225-1.795ZM7.07 13.995c-.01-.156-.023-.313-.023-.472 0-.52.068-1.022.184-1.506-2.224-.06-4.156-.744-5.231-1.796v.811c0 1.587 2.233 2.834 5.07 2.963ZM7.545 11.032h.001a6.496 6.496 0 0 1 1.4-2.086c-.45.057-.915.094-1.401.094C5.18 9.04 3.126 8.335 2 7.232v.812c0 1.675 2.473 2.988 5.545 2.988Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(2 2)" d="M0 0h17v17H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgComponent
