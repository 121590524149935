export default function formatBytes(bytes, unit) {
  const k = 1024
  let value, measure

  if (bytes === 0) {
    value = 0
    measure = 'Bytes'
  } else if (!unit) {
    if (bytes < 1) {
      value = bytes
      measure = 'Bytes'
    } else {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))

      value = parseFloat((bytes / Math.pow(k, i)).toFixed(2))
      measure = sizes[i]
    }
  } else {
    let convertedValue
    measure = unit

    switch (unit) {
      case 'KB':
        convertedValue = bytes / k
        break
      case 'MB':
        convertedValue = bytes / (k * k)
        break
      case 'GB':
        convertedValue = bytes / (k * k * k)
        break
      case 'TB':
        convertedValue = bytes / (k * k * k * k)
        break
    }

    if (Number.isInteger(convertedValue)) {
      value = convertedValue
    } else {
      const tail = convertedValue.toString().split('.')[1].split('')
      const index = tail.findIndex(el => el !== '0')
      value = convertedValue.toFixed(index + 1)
    }
  }
  const string = `${value} ${measure}`
  return { value, measure, string }
}
