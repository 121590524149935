export default function SvgComponent(props) {
  return (
    <svg width={26} height={26} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.609 8.39A6.557 6.557 0 0 0 13 6.464c-1.788 0-3.439.722-4.608 1.926a6.556 6.556 0 0 0-1.926 4.608c0 1.789.756 3.44 1.926 4.609A6.557 6.557 0 0 0 13 19.533c1.789 0 3.44-.723 4.609-1.926a6.557 6.557 0 0 0 1.926-4.609c0-1.788-.723-3.439-1.926-4.608ZM12.999 4.437a.91.91 0 0 0 .894-.895V.894A.91.91 0 0 0 12.999 0a.91.91 0 0 0-.895.894v2.648a.91.91 0 0 0 .895.895ZM20.324 6.948l1.892-1.892a.872.872 0 0 0 0-1.238.872.872 0 0 0-1.238 0L19.086 5.71a.872.872 0 0 0 0 1.238c.31.344.86.344 1.238 0ZM25.105 12.104h-2.648a.91.91 0 0 0-.895.895.91.91 0 0 0 .895.895h2.648a.91.91 0 0 0 .894-.895.91.91 0 0 0-.894-.895ZM20.288 19.052a.872.872 0 0 0-1.238 0 .872.872 0 0 0 0 1.238l1.892 1.892a.872.872 0 0 0 1.238 0 .872.872 0 0 0 0-1.239l-1.892-1.891ZM12.999 21.564a.91.91 0 0 0-.895.895v2.648A.91.91 0 0 0 13 26a.91.91 0 0 0 .894-.894v-2.648a.91.91 0 0 0-.894-.895ZM5.674 19.052l-1.892 1.891a.872.872 0 0 0 0 1.238.872.872 0 0 0 1.238 0l1.892-1.891a.872.872 0 0 0 0-1.238c-.31-.344-.86-.344-1.238 0ZM4.437 13a.91.91 0 0 0-.895-.896H.894A.91.91 0 0 0 0 13a.91.91 0 0 0 .894.895h2.648a.91.91 0 0 0 .895-.895ZM5.674 6.948a.872.872 0 0 0 1.238 0 .872.872 0 0 0 0-1.238L5.02 3.818a.872.872 0 0 0-1.238 0 .872.872 0 0 0 0 1.238l1.892 1.892Z"
        fill="rgba(253, 252, 255, 1)"
      />
    </svg>
  )
}
