import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={darkTheme ? '#decbfe' : '#ae9ccd'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4338 6.00557L14.7158 8.72363C14.4229 9.01652 14.4229 9.49139 14.7158 9.78429C15.0087 10.0772 15.4835 10.0772 15.7764 9.78429L18.4947 7.06597L18.4903 8.92655C18.4893 9.34077 18.8243 9.67736 19.2385 9.67835C19.6527 9.67934 19.9893 9.34436 19.9903 8.93015L19.9967 6.2581C19.999 5.28857 19.2126 4.50193 18.2431 4.50391L15.5698 4.50937C15.1555 4.51022 14.8204 4.84669 14.8213 5.2609C14.8221 5.67511 15.1586 6.01021 15.5728 6.00937L17.4338 6.00557ZM6.25445 6.00391C6.11596 6.00361 6.00361 6.11595 6.00391 6.25445L6.00972 8.92707C6.01062 9.34129 5.67557 9.6778 5.26136 9.6787C4.84714 9.6796 4.51063 9.34455 4.50973 8.93034L4.50391 6.25771C4.5018 5.28824 5.28824 4.5018 6.25771 4.50391L8.93034 4.50973C9.34455 4.51063 9.6796 4.84714 9.6787 5.26136C9.6778 5.67557 9.34129 6.01062 8.92707 6.00972L6.25445 6.00391ZM9.78227 15.7764C10.0752 15.4835 10.0752 15.0087 9.78226 14.7158C9.48937 14.4229 9.01449 14.4229 8.7216 14.7158L6.00372 17.4337L6.00777 15.5759C6.00867 15.1616 5.67361 14.8251 5.2594 14.8242C4.84519 14.8233 4.50867 15.1584 4.50777 15.5726L4.50196 18.2447C4.49985 19.2141 5.28609 20.0004 6.25544 19.9985L8.92824 19.9932C9.34246 19.9924 9.67757 19.6559 9.67675 19.2417C9.67593 18.8275 9.33947 18.4924 8.92526 18.4932L7.06186 18.4969L9.78227 15.7764ZM19.9912 15.5726C19.9903 15.1584 19.6538 14.8233 19.2396 14.8242C18.8254 14.8251 18.4903 15.1616 18.4912 15.5759L18.4971 18.2485C18.4974 18.387 18.385 18.4993 18.2465 18.499L15.5739 18.4932C15.1597 18.4923 14.8232 18.8274 14.8223 19.2416C14.8214 19.6558 15.1564 19.9923 15.5706 19.9932L18.2433 19.999C19.2127 20.0011 19.9992 19.2147 19.9971 18.2452L19.9912 15.5726Z"
      />
    </svg>
  )
}
