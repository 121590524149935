import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={darkTheme ? '#decbfe' : '#ae9ccd'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.6024 11.0336V10.353C18.6024 6.712 15.6404 3.75 11.9994 3.75C8.35836 3.75 5.39636 6.712 5.39636 10.353V11.0341C5.78262 10.9739 6.18256 11.0229 6.55743 11.1839C7.18643 11.4539 7.63843 11.9889 7.79843 12.6539L8.70543 16.4509C8.86443 17.1099 8.70643 17.7899 8.27443 18.3159C7.86843 18.8079 7.28243 19.0859 6.65443 19.0859C6.62448 19.0859 6.59545 19.0849 6.56556 19.084L6.56243 19.0839C6.34543 19.0739 6.12443 19.0479 5.90043 19.0039C4.35443 18.6999 3.10043 17.5199 2.70943 15.9979C2.31297 14.4543 2.78469 12.8908 3.89636 11.8591V10.353C3.89636 5.885 7.53136 2.25 11.9994 2.25C16.4674 2.25 20.1024 5.885 20.1024 10.353V11.8562C21.216 12.8879 21.688 14.4529 21.2895 15.9979C20.8995 17.5199 19.6465 18.6999 18.0985 19.0039C17.9306 19.0379 17.7628 19.0605 17.5975 19.0738C16.1838 21.4722 14.6516 21.7501 13.0389 21.7501C12.7989 21.7501 12.5571 21.7442 12.3132 21.7383L12.3069 21.7381C12.0509 21.7311 11.7859 21.7241 11.5109 21.7241C11.0969 21.7241 10.7609 21.3881 10.7609 20.9741C10.7609 20.5601 11.0969 20.2241 11.5109 20.2241C11.7989 20.2241 12.0769 20.2311 12.3459 20.2381H12.347C14.0094 20.2809 15.0089 20.3066 16.0886 18.6645C15.9564 18.5646 15.8344 18.4479 15.7255 18.3159C15.2935 17.7899 15.1365 17.1099 15.2945 16.4499L16.2025 12.6549C16.3615 11.9889 16.8135 11.4539 17.4425 11.1839C17.8176 11.0234 18.2169 10.9742 18.6024 11.0336Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 12.342C11.25 12.756 11.586 13.092 12 13.092C12.414 13.092 12.75 12.756 12.75 12.342V9.25C12.75 8.836 12.414 8.5 12 8.5C11.586 8.5 11.25 8.836 11.25 9.25V12.342ZM11.2544 14.7497C11.2544 15.1637 11.5904 15.4947 12.0044 15.4947C12.4184 15.4947 12.7544 15.1537 12.7544 14.7397C12.7544 14.3257 12.4184 13.9897 12.0044 13.9897C11.5904 13.9897 11.2544 14.3257 11.2544 14.7397V14.7497Z"
      />
    </svg>
  )
}
