export default function SvgComponent(props) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#F79649"
        d="M15.4655 12.3981L8.92548 0.766106C8.51223 0.0310898 7.47908 0.0310898 7.06583 0.766106L0.525859 12.3981C0.112609 13.1331 0.629172 14.0519 1.45569 14.0519H14.5356C15.3621 14.0519 15.8787 13.1331 15.4655 12.3981ZM7.30928 4.70897C7.50116 4.47868 7.74502 4.36351 8.04255 4.36351C8.34022 4.36351 8.58394 4.47751 8.77594 4.70436C8.96666 4.93178 9.06205 5.2162 9.06205 5.55818C9.06205 5.85237 8.66377 8.01606 8.53103 9.59017H7.57166C7.45511 8.01609 7.023 5.85242 7.023 5.55818C7.02305 5.22139 7.11859 4.93814 7.30928 4.70897ZM8.76236 12.1937C8.56063 12.4119 8.32053 12.5207 8.04258 12.5207C7.76475 12.5207 7.52452 12.4119 7.32278 12.1937C7.12158 11.9761 7.02148 11.7124 7.02148 11.4026C7.02148 11.0946 7.12156 10.828 7.32278 10.6046C7.52452 10.3812 7.76475 10.2695 8.04258 10.2695C8.32053 10.2695 8.56064 10.3812 8.76236 10.6046C8.96356 10.828 9.06378 11.0946 9.06378 11.4026C9.06378 11.7124 8.96358 11.9761 8.76236 12.1937Z"
      />
    </svg>
  )
}
