import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill={darkTheme ? '#fff' : '#392955'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.45361 3.13207H11.2262V10.0943L12.0703 10.9717L12.9425 10.0943V3.18868H14.7432L12.0703 0.5L9.45361 3.13207Z"
      />
      <path
        d="M24.0002 12.5283L21.3554 9.83966V11.6227H14.5465L13.6743 12.5283L14.4621 13.3208H21.2992V15.2736L24.0002 12.5283Z"
      />
      <path
        d="M16.0939 3.7547L18.0353 5.73583L13.5054 10.3207V11.0566H14.3213L18.8231 6.49998L20.7926 8.45281V3.7547H16.0939Z"
      />
      <path
        d="M15.3061 3.7547H13.5054V9.52829L17.2475 5.73583L15.3061 3.7547Z"
      />
      <path
        d="M15.1089 11.0566H20.7924V9.27361L18.8228 7.29248L15.1089 11.0566Z"
      />
      <path
        d="M14.7151 21.8396H12.9144V14.9906L12.014 14.1132L11.2262 14.8774V21.8396H9.31299L12.014 24.5L14.7151 21.8396Z"
      />
      <path
        d="M14.2367 13.8868H13.4771V14.7642L18.0632 19.2925L16.0937 21.2736H20.7361V16.6321L18.8229 18.5567L14.2367 13.8868Z"
      />
      <path
        d="M15.0244 13.8868L18.8228 17.7642L20.736 15.8397V13.8868H15.0244Z"
      />
      <path
        d="M13.4771 15.5566V21.2736H15.2778L17.2473 19.2925L13.4771 15.5566Z"
      />
      <path
        d="M8.10294 3.69812H3.31982V8.42454L5.26121 6.50001L9.7911 11.0283H10.6633V10.3208L6.10529 5.73586L8.10294 3.69812Z"
      />
      <path
        d="M8.89072 3.69812L6.89307 5.73586L10.6633 9.50001V3.69812H8.89072Z"
      />
      <path
        d="M3.31982 9.21701V11.0283H9.00329L5.26121 7.29248L3.31982 9.21701Z"
      />
      <path
        d="M0 12.5L2.75733 15.2453V13.3208H9.65064L10.4947 12.5L9.56624 11.5944H2.75733V9.75476L0 12.5Z"
      />
      <path
        d="M9.90365 13.8868L5.26121 18.5L3.31982 16.6038V21.2736H7.93413L5.96461 19.2925L10.6633 14.651V13.8868H9.90365Z"
      />
      <path
        d="M8.72196 21.2736H10.6633V15.4434L6.75244 19.2925L8.72196 21.2736Z"
      />
      <path
        d="M9.0877 13.8868H3.31982V15.8114L5.26121 17.7076L9.0877 13.8868Z"
      />
    </svg>
  )
}
