import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { Icon, ImageModal } from '@components'
import { Field, ErrorMessage } from 'formik'
import s from './MessageInput.module.scss'
import { checkIsImageFile } from '@src/utils'

export default function Component(props) {
  const {
    files,
    message,
    onChangeFiles,
    onKeyDown,
    filesError,
    enableFiles = true,
    name = 'message',
    textareaClassName,
    placeholderText,
    label,
    isRequired,
    disabled,
    fieldsBlockclassName,
  } = props
  const { t } = useTranslation(['support', 'other'])

  const [images, setImages] = useState([])
  const [imageIsOpened, setImageIsOpened] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const textarea = useRef(null)
  useEffect(() => {
    textarea.current.style.height = '30px'
    const scrollHeight = textarea.current.scrollHeight
    textarea.current.style.height = scrollHeight + 'px'
  }, [message])

  useEffect(() => {
    const images = files
      ?.filter(el => checkIsImageFile(el.name))
      .map(el => ({ id: `${el.name}${el.size}`, img: URL.createObjectURL(el) }))
    setImages(images)
  }, [files])

  const closeImageHandler = () => setImageIsOpened(false)

  const handlePaste = event => {
    if (enableFiles) {
      const items = event.clipboardData.items
      const newFiles = []

      for (let item of items) {
        if (item.kind === 'file') {
          const file = item.getAsFile()
          newFiles.push(file)
        }
      }

      if (newFiles.length > 0) {
        const readers = newFiles.map(file => {
          return new Promise(resolve => {
            const reader = new FileReader()
            reader.onload = () => {
              resolve(file)
            }
            reader.readAsDataURL(file)
          })
        })

        Promise.all(readers).then(loadedFiles => {
          onChangeFiles(files.concat(loadedFiles))
        })

        event.preventDefault()
      }
    }
  }

  const handleDrop = e => {
    if (enableFiles) {
      e.preventDefault()

      const droppedFiles = Array.from(e.dataTransfer.files)
      const newFiles = []

      droppedFiles.forEach(file => {
        const reader = new FileReader()
        reader.onload = () => {
          newFiles.push(file)

          if (newFiles.length === droppedFiles.length) {
            onChangeFiles(files.concat(newFiles))
          }
        }
        reader.readAsDataURL(file)
      })
    }
    setIsDragging(false)
  }

  const handleDragOver = e => {
    if (enableFiles) {
      e.preventDefault()
      setIsDragging(true)
    }
  }
  const handleDragLeave = e => {
    if (enableFiles) {
      e.preventDefault()
      setIsDragging(false)
    }
  }

  const handleDragEnter = e => {
    if (enableFiles) {
      e.preventDefault()
    }
  }

  return (
    <div
      className={s.messageContainer}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
    >
      {isDragging && <div className={s.dragging}>{t('drop_here')}</div>}
      {label && (
        <label htmlFor={name} className={s.label}>
          {isRequired ? requiredLabel(label) : label}
        </label>
      )}
      <div className={cn(s.fieldsBlock, fieldsBlockclassName)}>
        <div className={s.messageBlock}>
          <Field
            data-testid="input_message"
            innerRef={textarea}
            className={cn(s.textarea, { [textareaClassName]: textareaClassName })}
            type="text"
            name={name}
            id={name}
            placeholder={placeholderText || t('Enter your message...')}
            as="textarea"
            onKeyDown={onKeyDown}
            disabled={disabled}
            onPaste={handlePaste}
          />
          {enableFiles && (
            <label htmlFor="files">
              <div
                className={cn(s.filesBlock, {
                  [s.notEmpty]: files?.length > 0,
                })}
              >
                <Icon name="Clip" />
              </div>
              <input
                hidden
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, .pptx, .docx,
             application/pdf, image/*, audio/*, video/*, .zip, .rar, .html, .csv"
                disabled={files?.length === 5}
                id="files"
                name="files"
                type="file"
                onChange={e =>
                  e?.target?.files?.length !== 0 &&
                  onChangeFiles(files.concat(e.target.files[0]))
                }
              />
            </label>
          )}
        </div>
        {enableFiles && files?.length > 0 && (
          <div className={s.filesContainer}>
            {files?.map((el, index) => {
              const img = images.find(img => img.id === `${el.name}${el.size}`)?.img
              return (
                <div
                  className={cn(s.fileItem, { [s.bigfile]: el?.size >= 10000000 })}
                  key={index}
                >
                  {checkIsImageFile(el.name) ? (
                    <button onClick={() => setImageIsOpened({ img, name: el.name })}>
                      <img src={img} alt={el?.name} className={s.img_thumbnail} />
                    </button>
                  ) : (
                    el?.name
                  )}

                  <button
                    type="button"
                    onClick={() => {
                      let newArr = files
                        .slice(0, index)
                        .concat(files.slice(index + 1, files?.length))
                      onChangeFiles(newArr)
                    }}
                    className={s.fileDeleteItem}
                  >
                    <Icon name="Cross" />
                  </button>
                </div>
              )
            })}
          </div>
        )}
      </div>
      {filesError && (
        <div className={s.fileError}>
          {t('The size of the collected file should not exceed 10.0 MB')}
        </div>
      )}
      <ErrorMessage className={s.fileError} name={name} component="span" />
      <ImageModal imageIsOpened={imageIsOpened} closeImageHandler={closeImageHandler} />
    </div>
  )
}

function requiredLabel(labelName) {
  return (
    <>
      {labelName} {<span className={s.required_star}>*</span>}
    </>
  )
}
