import { TARIFFS_PRICES } from '../constants'
import cutDcSuffix from '../cutDcSuffix'
import roundToDecimal from '../roundToDecimal'

export default function rewriteCloudsPrices(payload) {
  if (payload) {
    try {
      const key = Object.keys(payload)[0]

      payload[key] = payload[key]?.map(el => {
        const name = el.title?.main?.$ || el.title?.$

        const cutedName = cutDcSuffix(name)

        const newDayPrice = TARIFFS_PRICES[cutedName]?.day
        const newMonthPrice = TARIFFS_PRICES[cutedName]?.month
        const hourPrice = roundToDecimal(newDayPrice / 24)

        const CLOUD_NEW_PRICES = {
          '-50': newDayPrice,
          1: newMonthPrice,
          3: roundToDecimal(newMonthPrice * 3),
          6: roundToDecimal(newMonthPrice * 6),
          12: roundToDecimal(newMonthPrice * 12),
        }

        el.title.$ = cutedName

        el.prices.price.forEach(price => {
          price.cost.$ = CLOUD_NEW_PRICES[price.period.$]
        })
        el.prices.price.push({ cost: { $: String(hourPrice) }, period: { $: 'hour' } })

        return el
      })

      return payload
    } catch (err) {
      console.error(err.message)
      throw err
    }
  } else {
    return {}
  }
}
