import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={darkTheme ? '#decbfe' : '#ae9ccd'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.997C9.306 16.997 7.114 14.805 7.114 12.111C7.114 10.634 7.774 9.25 8.925 8.314C9.246 8.052 9.718 8.102 9.98 8.423C10.241 8.744 10.192 9.217 9.871 9.478C9.072 10.127 8.614 11.087 8.614 12.111C8.614 13.978 10.133 15.497 12 15.497C13.867 15.497 15.386 13.978 15.386 12.111C15.386 11.087 14.928 10.127 14.129 9.477C13.808 9.216 13.76 8.744 14.021 8.422C14.283 8.101 14.756 8.053 15.077 8.314C16.227 9.25 16.886 10.634 16.886 12.111C16.886 14.805 14.694 16.997 12 16.997ZM11.25 7.752C11.25 7.338 11.586 7.002 12 7.002C12.414 7.002 12.75 7.338 12.75 7.752V11.356C12.75 11.77 12.414 12.106 12 12.106C11.586 12.106 11.25 11.77 11.25 11.356V7.752ZM16.218 2.5H7.783C4.623 2.5 2.5 4.721 2.5 8.026V15.974C2.5 19.279 4.623 21.5 7.783 21.5H16.217C19.377 21.5 21.5 19.279 21.5 15.974V8.026C21.5 4.721 19.377 2.5 16.218 2.5Z"
      />
    </svg>
  )
}
