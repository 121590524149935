import { forwardRef } from 'react'
import { Formik, Form } from 'formik'
import {
  InputField,
  Button,
  Icon,
  Portal,
  CheckBox,
  DoubleInputField,
  Select,
} from '@components'
import s from './Filter.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { exists as isTranslationExists } from 'i18next'
import { onlyDigitsValue } from '@utils'
export default forwardRef(function FilterModal(
  {
    isFiltersOpened,
    filters,
    filterState,
    closeFilterModal,
    setFiltersHandler,
    clearFilterValues,
    fields,
    isFiltered,
  },
  ref,
) {
  const { t } = useTranslation(['filter'])

  return (
    <div ref={ref} className={cn(s.filters_modal, { [s.opened]: isFiltersOpened })}>
      <Formik
        initialValues={filterState}
        onSubmit={values => {
          closeFilterModal()
          setFiltersHandler(values)
        }}
      >
        {({ setFieldValue, values, errors, touched, dirty }) => {
          const setOnlyDigitsHandler = (field, value) => {
            const transValue = onlyDigitsValue(value)
            setFieldValue(field, transValue)
          }

          return (
            <Form>
              <div className={s.formHeader}>
                <h2>{t('filter')}</h2>
                <Icon name="Cross" onClick={closeFilterModal} className={s.crossIcon} />
              </div>
              <div className={cn(s.form)}>
                <div className={s.fieldsBlock}>
                  {fields
                    .filter(field => !field.hidden)
                    .map(field => {
                      if (field.type === 'checkbox') {
                        return (
                          <span className={s.checkbox_field} key={field.label}>
                            <CheckBox
                              value={values[field.value] === field.checkboxValues.checked}
                              onClick={() => {
                                setFieldValue(
                                  field.value,
                                  values[field.value] === field.checkboxValues.checked
                                    ? ''
                                    : field.checkboxValues.checked,
                                )
                              }}
                            />
                            <span>{t(field.label)}</span>
                          </span>
                        )
                      } else if (field.type === 'select') {
                        const options =
                          field.options ??
                          filters.slist
                            .find(list => list.$name === field.value)
                            ?.val.filter(el => !field.excludeOptions?.includes(el.$))
                            .map(el => ({
                              label: el.$key,
                              value: el.$,
                            }))

                        const itemList = options.map(el => ({
                          ...el,
                          label: isTranslationExists(
                            `select.${field.label}.${el.label}`,
                            {
                              ns: 'filter',
                            },
                          )
                            ? t(`select.${field.label}.${el.label}`)
                            : el.label,
                        }))

                        return (
                          <Select
                            dropdownClass={s.input_bgc}
                            inputClassName={s.input_bgc}
                            label={`${t(field.label)}:`}
                            placeholder={t('not_selected')}
                            value={values[field.value]}
                            getElement={item => setFieldValue(field.value, item)}
                            isShadow
                            itemsList={itemList}
                            className={s.select}
                            hasNotSelectedOption
                            key={field.label}
                          />
                        )
                      } else if (field.type === 'date_range') {
                        const group = field.groupLabel

                        const leftField = field
                        const rightField = fields.find(
                          el => el.groupLabel === group && el.rangeSide === 'right',
                        )

                        let datesOrdered = null
                        if (values[leftField.value] && values[rightField.value]) {
                          datesOrdered = [
                            new Date(values[leftField.value]),
                            new Date(values[rightField.value]),
                          ]
                        } else if (values[leftField.value]) {
                          datesOrdered = new Date(values[leftField.value])
                        }

                        return (
                          <DoubleInputField
                            inputWrapperClass={cn(s.inputHeight, s.input_bgc)}
                            className={s.input}
                            nameLeft={leftField.value}
                            nameRight={rightField.value}
                            valueLeft={values[leftField.value]}
                            onChangeLeft={() => null}
                            valueRight={values?.[rightField.value]}
                            onChangeRight={() => null}
                            label={`${t(field.groupLabel)}:`}
                            placeholderLeft="00/00/00"
                            placeholderRight="00/00/00"
                            isCalendar
                            dates={datesOrdered}
                            range={values[leftField.value]?.length !== 0}
                            setFieldValue={setFieldValue}
                            isShadow
                            key={field.label}
                          />
                        )
                      } else if (field.type === 'digits_range') {
                        const group = field.groupLabel

                        const leftField = field
                        const rightField = fields.find(
                          el => el.groupLabel === group && el.rangeSide === 'right',
                        )

                        return (
                          <DoubleInputField
                            inputWrapperClass={cn(s.inputHeight, s.input_bgc)}
                            className={s.input}
                            nameLeft={leftField.label}
                            nameRight={rightField.label}
                            valueLeft={values[leftField.value]}
                            onChangeLeft={e =>
                              setOnlyDigitsHandler(leftField.value, e.target.value)
                            }
                            valueRight={values?.[rightField.value]}
                            onChangeRight={e =>
                              setOnlyDigitsHandler(rightField.value, e.target.value)
                            }
                            label={`${t(field.groupLabel)}:`}
                            placeholderLeft={t(leftField.label)}
                            placeholderRight={t(rightField.label)}
                            setFieldValue={setFieldValue}
                            isShadow
                            key={field.label}
                          />
                        )
                      } else {
                        return (
                          <InputField
                            key={field.label}
                            inputWrapperClass={s.inputHeight}
                            inputClassName={s.input_bgc}
                            name={field.value}
                            label={`${t(field.label)}:`}
                            placeholder={`${t(field.label)}`}
                            isShadow
                            className={s.input}
                            error={!!errors[field.value]}
                            touched={!!touched[field.value]}
                            onChange={
                              field.type === 'number'
                                ? e => setOnlyDigitsHandler(field.value, e.target.value)
                                : e => setFieldValue(field.value, e.target.value)
                            }
                          />
                        )
                      }
                    })}
                </div>
              </div>
              <div className={cn(s.btnBlock)}>
                <Button
                  className={s.searchBtn}
                  isShadow
                  size="medium"
                  label={t('search')}
                  type="submit"
                  onClick={e => {
                    if (!dirty) {
                      e.preventDefault()
                      closeFilterModal()
                    }
                  }}
                />
                <button
                  onClick={() => {
                    closeFilterModal()
                    isFiltered && setFiltersHandler(clearFilterValues)
                  }}
                  type="button"
                  className={s.clearFilters}
                >
                  {t('clear_filter')}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
      <Portal>
        <div className={cn(s.filter_backdrop, { [s.opened]: isFiltersOpened })}></div>
      </Portal>
    </div>
  )
})
