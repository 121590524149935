const SvgComponent = props => (
  <svg width={21} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M21 3.5H0v14h21v-14Z" fill="#F0F0F0" />
    <path
      d="M21 5.25H0V7h21V5.25ZM21 8.75H0v1.75h21V8.75ZM21 12.25H0V14h21v-1.75ZM21 15.75H0v1.75h21v-1.75Z"
      fill="#D80027"
    />
    <path d="M10.5 3.5H0v7.539h10.5V3.5Z" fill="#2E52B2" />
    <path
      d="m4.094 6.59-.17.52h-.546l.443.321-.17.52.443-.32.442.32-.169-.52.443-.32h-.547l-.169-.521ZM4.263 8.985l-.169-.52-.17.52h-.546l.443.321-.17.52.443-.32.442.32-.169-.52.443-.321h-.547ZM1.951 8.985l-.169-.52-.169.52h-.547l.443.321-.17.52.444-.32.442.32-.17-.52.443-.321h-.547ZM1.782 6.59l-.169.52h-.547l.443.321-.17.52.444-.32.442.32-.17-.52.443-.32h-.547l-.169-.521ZM4.094 4.71l-.17.521h-.546l.443.322-.17.52.443-.322.442.322-.169-.52.443-.322h-.547l-.169-.52ZM1.782 4.71l-.169.521h-.547l.443.322-.17.52.444-.322.442.322-.17-.52.443-.322h-.547l-.169-.52ZM6.405 6.59l-.169.52H5.69l.443.321-.169.52.442-.32.443.32-.17-.52.443-.32h-.547l-.168-.521ZM6.574 8.985l-.168-.52-.17.52H5.69l.443.321-.169.52.442-.32.443.32-.17-.52.443-.321h-.547ZM8.887 8.985l-.169-.52-.17.52h-.546l.442.321-.169.52.443-.32.442.32-.169-.52.443-.321h-.547ZM8.718 6.59l-.17.52h-.546l.442.321-.169.52.443-.32.442.32-.169-.52.443-.32h-.547l-.169-.521ZM6.405 4.71l-.169.521H5.69l.443.322-.169.52.442-.322.443.322-.17-.52.443-.322h-.547l-.168-.52ZM8.718 4.71l-.17.521h-.546l.442.322-.169.52.443-.322.442.322-.169-.52.443-.322h-.547l-.169-.52Z"
      fill="#F0F0F0"
    />
  </svg>
)

export default SvgComponent
