import { Icon, TooltipWrapper } from '@components'
import { getInstanceMainInfo } from '@utils'
import s from './Status.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

export default function Status({ item }) {
  const { t } = useTranslation(['cloud_vps', 'vds', 'countries', 'filter'])

  const {
    isResized,
    displayStatus,
    isDeleting,
    isSuspended,
    isExpired,
    isActivationAfterExpired,
  } = getInstanceMainInfo(item)

  const isHintStatus =
    (isSuspended && !isExpired && !isActivationAfterExpired) || isResized
  const hintMessage = isResized ? t('resize_popup_text') : t('by_admin')

  return (
    <>
      {isHintStatus && !isDeleting ? (
        <TooltipWrapper
          className={s.popup}
          wrapperClassName={s.popup__wrapper}
          content={hintMessage}
        >
          <span className={cn(s.status, s[displayStatus])}>
            {displayStatus}
            <Icon name="Attention" />
          </span>
        </TooltipWrapper>
      ) : (
        <span className={cn(s.status, s[displayStatus])}>{displayStatus}</span>
      )}
    </>
  )
}
