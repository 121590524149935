import { useEffect, useRef, useState } from 'react'
import s from './InstancesList.module.scss'
import cn from 'classnames'
import {
  AutoprolongIcon,
  CopyText,
  EditCell,
  InstancesOptions,
  Status,
} from '@components'
import * as route from '@src/routes'
import { useNavigate } from 'react-router-dom'
import {
  getFlagFromCountryName,
  getInstanceMainInfo,
  formatCountryName,
  cutDcSuffix,
  getImageIconName,
} from '@utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function InstanceItemMobile({ item, editInstance }) {
  const { t } = useTranslation(['cloud_vps', 'countries'])
  const optionsBlock = useRef()
  const header = useRef()
  const navigate = useNavigate()
  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  const { isNotActive } = getInstanceMainInfo(item)

  const [serverName, setServerName] = useState(item.servername?.$ || '')
  const itemCountry = formatCountryName(item, t)
  const ip = item?.ip?.$ || item?.ip_v6?.$

  const osIcon = getImageIconName(item?.os_distro?.$, darkTheme)

  const tooltipContent = () => {
    if (!item?.os_distro?.$ && !item?.os_version?.$) {
      return t('select.os_distro.detecting', { ns: 'filter' })
    }

    const osDistro = item?.os_distro?.$ ? item.os_distro.$ : ''
    const osVersion = item?.os_version?.$ ? item.os_version.$ : ''

    return `${osDistro} ${osVersion}`.trim()
  }

  const editServerName = value => {
    const slicedValue = value.slice(0, 100)
    editInstance({
      value: slicedValue,
      elid: item.id.$,
      errorCallback: () => setServerName(serverName),
    })
    setServerName(value)
  }

  useEffect(() => {
    setServerName(item.servername?.$ || '')
  }, [item.servername?.$])

  return (
    <div
      className={s.mobile_item}
      onClick={e => {
        if (
          optionsBlock.current.contains(e.target) ||
          header.current.contains(e.target) ||
          isNotActive
        )
          return
        navigate(`${route.CLOUD_VPS}/${item.id.$}`, { state: item })
      }}
      tabIndex={0}
      onKeyUp={() => {}}
      role="button"
    >
      <div className={s.mobile_item__header} ref={header}>
        <div className={s.mobile_item__header_name}>
          <p className={s.mobile_item__name}>
            <EditCell
              originName={serverName}
              onSubmit={editServerName}
              placeholder={t(serverName || t('server_placeholder', { ns: 'vds' }), {
                ns: 'vds',
              })}
            />
          </p>
          <div className={s.status_wrapper}>
            <Status item={item} />
          </div>
        </div>
        <div ref={optionsBlock}>
          <InstancesOptions item={item} isMobile />
        </div>
      </div>
      <div className={s.mobile_item__body}>
        <div className={s.mobile_item__param}>{t('Flavor')}</div>
        <div className={s.mobile_item__value}>{cutDcSuffix(item.pricelist.$)}</div>

        <div className={s.mobile_item__param}>{t('Price')}</div>
        <div className={s.mobile_item__value}>
          {item.cost.$.replace('Day', t('day'))
            .replace('Month', t('Month', { ns: 'dedicated_servers' }))
            .replace('Year', t('Year', { ns: 'dedicated_servers' }))}
        </div>

        <div className={s.mobile_item__param}>{t('Region')}</div>
        <div className={cn(s.mobile_item__value, s.withIcon)}>
          {item?.datacentername && (
            <>
              <img
                src={require(`@images/countryFlags/${getFlagFromCountryName(
                  itemCountry?.countryName,
                )}.png`)}
                width={20}
                height={14}
                alt={itemCountry?.countryName}
              />
              <span>{itemCountry?.DCName}</span>
            </>
          )}
        </div>

        <div className={s.mobile_item__param}>{t('Created at')}</div>
        <div className={s.mobile_item__value}>
          {item.createdate.$} <br />
          {item.i_opentime?.$}
        </div>
        <div className={s.mobile_item__param}>{t('paid_until')}</div>
        <div className={s.mobile_item__value}>
          {item.real_expiredate?.$}
          <br />
          <div className={s.row}>
            {item.i_expiretime?.$}
            <AutoprolongIcon item={item} />
          </div>
        </div>
        {osIcon && (
          <>
            <div className={s.mobile_item__param}>{t('OS')}</div>
            <div className={cn(s.mobile_item__value, s.withIcon)}>
              <img
                src={require(`@images/soft_os_icons/${osIcon}.png`)}
                alt={item?.os_distro?.$}
              />
              <span>{tooltipContent()}</span>
            </div>
          </>
        )}

        <div className={s.mobile_item__param}>{t('Access IP')}</div>
        <div className={s.mobile_item__value}>
          <span className={s.ip_cell}>
            <span>{ip}</span>{' '}
            {ip && <CopyText text={ip} promptText={t('ip_address_copied')} />}
          </span>
        </div>
      </div>
    </div>
  )
}
