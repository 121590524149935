export default function getInstanceMainInfo(item) {
  if (!item) return {}
  try {
    const fotboStatus = item.instance_status?.$.trim().toLowerCase()
    const billingStatus = item.item_status?.$.trim()

    const isStopped = fotboStatus === 'stopped'
    const isActive = fotboStatus === 'active'
    const isResized = fotboStatus === 'resized'
    const isRescued = fotboStatus === 'rescued'
    const isSuspended = fotboStatus === 'suspended' || billingStatus === 'Suspended'
    const isExpired = isSuspended && item.status.$ === '3' && item.autosuspend?.$ === 'on'
    const isActivationAfterExpired =
      isSuspended &&
      (billingStatus === 'Activation in progress' || billingStatus === 'Ordered')
    const isBootedFromISO = fotboStatus === 'booted_from_iso'
    const isImageUploading = fotboStatus === 'image_uploading'
    const isErrorStatus = fotboStatus === 'error'
    const isWindows = item?.os_distro?.$?.toLowerCase()?.includes('windows')
    const isOrdering =
      billingStatus === 'Activation in progress' || billingStatus === 'Ordered'

    const isNotActive =
      item?.status?.$ === '1' || item?.status?.$ === '4' || item?.status?.$ === '5'

    const isDeleting = billingStatus === 'Deletion in progress'

    const isProcessing =
      fotboStatus === 'stopping' ||
      fotboStatus === 'starting' ||
      fotboStatus === 'creating' ||
      fotboStatus === 'building' ||
      fotboStatus === 'resizing' ||
      fotboStatus === 'processing' ||
      fotboStatus === 'rebooting' ||
      fotboStatus === 'rescuing' ||
      fotboStatus === 'unrescuing' ||
      fotboStatus === 'rebuilding' ||
      fotboStatus === 'suspending' ||
      fotboStatus === 'booting_from_iso' ||
      isImageUploading ||
      isDeleting ||
      isActivationAfterExpired ||
      isOrdering

    const isDisabled =
      isProcessing ||
      isNotActive ||
      isSuspended ||
      isDeleting ||
      isImageUploading ||
      isErrorStatus

    const displayName = item?.servername?.$ || item?.name?.$

    const displayStatus = (
      isDeleting || isActivationAfterExpired
        ? billingStatus
        : isSuspended
        ? isExpired
          ? 'expired'
          : 'suspended'
        : fotboStatus?.replaceAll('_', ' ') || billingStatus
    ).toLowerCase()

    const isRestoreAllowed = isActive || isStopped

    return {
      isNotActive,
      isDisabled,
      isProcessing,
      isStopped,
      isResized,
      isRescued,
      isSuspended,
      isWindows,
      displayName,
      displayStatus,
      isDeleting,
      isBootedFromISO,
      isImageUploading,
      isErrorStatus,
      isRestoreAllowed,
      isExpired,
      isActivationAfterExpired,
      isOrdering,
    }
  } catch (error) {
    console.log(error.message)
    return {}
  }
}
