export default function SvgComponent(props) {
  const { className } = props
  return (
    <svg width={24} height={23} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        className={className}
        d="M21.45 18.818h-3.662a4.188 4.188 0 0 1-4.185-4.182 4.188 4.188 0 0 1 4.185-4.181h3.663c.289 0 .523-.234.523-.523V8.364a2.087 2.087 0 0 0-1.926-2.074l-3.005-5.244a2.067 2.067 0 0 0-2.84-.764L3.901 6.273h-1.81A2.094 2.094 0 0 0 0 8.363V20.91C0 22.062.939 23 2.093 23H19.88a2.094 2.094 0 0 0 2.093-2.09V19.34a.523.523 0 0 0-.523-.523ZM17.693 4.284l1.14 1.989h-4.56l3.42-1.99ZM5.982 6.273l8.747-5.088a1.026 1.026 0 0 1 1.406.38v.003L8.048 6.273H5.981Z"
        fill="#D9D0EB"
      />
      <path
        className={className}
        d="M21.45 11.5h-3.662a3.141 3.141 0 0 0-3.14 3.136 3.141 3.141 0 0 0 3.14 3.137h3.662a1.57 1.57 0 0 0 1.57-1.569v-3.136a1.57 1.57 0 0 0-1.57-1.568Zm-3.662 4.182c-.577 0-1.046-.47-1.046-1.046a1.047 1.047 0 0 1 2.092 0c0 .577-.47 1.046-1.046 1.046Z"
        fill="#D9D0EB"
      />
    </svg>
  )
}
