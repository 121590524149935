export default function SvgComponent(props) {
  return (
    <svg width={16} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.06 8.41a.443.443 0 0 0 .442-.444V1.45a.443.443 0 0 0-.443-.444h-2.666V8.41h2.666ZM.554 5.96c0 .563.302 1.057.752 1.327a1.547 1.547 0 0 0 1.27 2.425h3.256c-.1.454-.236 1.135-.331 1.847-.248 1.854-.079 2.882.532 3.236.38.22.79.265 1.155.126.282-.108.662-.372.878-1.021l.857-2.247c.434-1.075 1.762-2.203 2.587-2.827V.81A14.276 14.276 0 0 0 6.762 0h-3.55a1.547 1.547 0 0 0-1.31 2.361 1.55 1.55 0 0 0-.596 2.27c-.45.271-.752.765-.752 1.328Z"
        fill="#878291"
      />
    </svg>
  )
}
