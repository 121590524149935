import { Icon, TooltipWrapper } from '@components'
import { useTranslation } from 'react-i18next'
import s from './AutoprolongIcon.module.scss'

export default function AutoprolongIcon({ item }) {
  const { t } = useTranslation(['autoprolong'])

  return (
    <>
      {item?.autoprolong?.$ && item?.autoprolong?.$ !== 'null' && (
        <TooltipWrapper wrapperClassName={s.wrapper} content={t('autoprolong_enabled')}>
          <Icon name="Clock" className={s.green_icon} />
        </TooltipWrapper>
      )}
    </>
  )
}
