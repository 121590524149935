import { useRef, useState, useEffect } from 'react'
import { Icon, Modal } from '..'
import s from './Options.module.scss'
import cn from 'classnames'
import { useOutsideAlerter } from '@utils'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

export default function Options({
  options,
  columns = 1,
  buttonClassName,
  listItemClassName,
  renderButton,
  isTileLayout,
  useModalOnMobile = false,
  mobileModalTitle,
}) {
  const dropdownEl = useRef()
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [optionsCount, setOptionsCount] = useState(0)
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1550px)' })

  useEffect(() => {
    const filtredArr = options.filter(option => !option.hidden)
    setOptionsCount(filtredArr.length)
  }, [options])

  const { t } = useTranslation(['other'])

  useOutsideAlerter(dropdownEl, isOptionsOpen, () => setIsOptionsOpen(false))

  const renderOptionsList = () =>
    options
      .filter(option => !option.hidden)
      .map(option => (
        <li
          key={option.label}
          className={cn(s.tool_item, {
            [s.tool_item_delete]: option.isDelete,
            [s.tile_item]: isTileLayout,
            [listItemClassName]: !isTileLayout,
            [s.modal_item]: isMobile && useModalOnMobile,
          })}
        >
          <button
            className={cn(s.tool_btn, {
              [s.tile_btn]: isTileLayout,
            })}
            type="button"
            onClick={() => {
              option.onClick()
              setIsOptionsOpen(false)
            }}
            disabled={option.disabled}
          >
            <Icon
              name={option.icon}
              className={cn(s.tool_icon, {
                [s.tool_icon_delete]: option.isDelete,
                [s.tile_icon]: isTileLayout,
              })}
              {...(option.icon === 'Clock' && { width: '24px', height: '20px' })}
            />
            {option.label}
          </button>
        </li>
      ))

  return isTileLayout ? (
    <ul
      className={cn(s.tools__list, s.tile_wrapper)}
      style={
        isDesktop && optionsCount < 6
          ? { gridTemplateColumns: `repeat(${optionsCount}, 110px)` }
          : undefined
      }
    >
      {renderOptionsList()}
    </ul>
  ) : (
    <div className={s.wrapper}>
      <button
        className={cn(s.btn, [buttonClassName], { [s.opened]: isOptionsOpen })}
        type="button"
        onClick={() => setIsOptionsOpen(true)}
      >
        {renderButton?.() ?? <Icon name="Settings" />}

        {isOptionsOpen && (!isMobile || !useModalOnMobile) && (
          <div className={s.pointer_wrapper}>
            <div className={s.pointer}></div>
          </div>
        )}
      </button>

      {isOptionsOpen &&
        (isMobile && useModalOnMobile ? (
          <Modal
            isOpen={isOptionsOpen}
            closeModal={e => {
              e?.stopPropagation()
              setIsOptionsOpen(false)
            }}
            isClickOutside
          >
            <Modal.Header>
              <span className={s.headerText}>
                {mobileModalTitle
                  ? t(mobileModalTitle, { ns: 'other' })
                  : t('service', { ns: 'other' })}
              </span>
            </Modal.Header>
            <Modal.Body>
              <ul className={cn(s.modal__list)}>{renderOptionsList()}</ul>
            </Modal.Body>
          </Modal>
        ) : (
          <div className={s.dropdown} ref={dropdownEl}>
            <ul
              className={cn(s.tools__list)}
              style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
            >
              {renderOptionsList()}
            </ul>
          </div>
        ))}
    </div>
  )
}
