import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={darkTheme ? '#decbfe' : '#ae9ccd'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75276 17.9043C6.45976 17.6113 5.98476 17.6113 5.69176 17.9043L4.77776 18.8183L3.86376 17.9043C3.57076 17.6113 3.09576 17.6113 2.80276 17.9043C2.65676 18.0503 2.58376 18.2423 2.58376 18.4343C2.58376 18.6263 2.65676 18.8183 2.80276 18.9653L3.71676 19.8803L2.80276 20.7943C2.50976 21.0873 2.50976 21.5613 2.80276 21.8543C3.09576 22.1473 3.57076 22.1473 3.86376 21.8543L4.77776 20.9403L5.69176 21.8543C5.83876 22.0013 6.03076 22.0743 6.22276 22.0743C6.41476 22.0743 6.60676 22.0013 6.75276 21.8543C6.89876 21.7083 6.97276 21.5163 6.97276 21.3243C6.97276 21.1333 6.89876 20.9403 6.75276 20.7943L5.83876 19.8803L6.75276 18.9653C7.04576 18.6723 7.04576 18.1973 6.75276 17.9043Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2833 19.8793L21.1973 18.9643C21.4903 18.6713 21.4903 18.1963 21.1973 17.9033C20.9043 17.6103 20.4293 17.6103 20.1373 17.9033L19.2223 18.8173L18.3083 17.9033C18.0153 17.6103 17.5403 17.6103 17.2473 17.9033C17.1013 18.0503 17.0283 18.2413 17.0283 18.4343C17.0283 18.6253 17.1013 18.8173 17.2473 18.9643L18.1613 19.8793L17.2473 20.7933C16.9543 21.0863 16.9543 21.5613 17.2473 21.8543C17.5403 22.1473 18.0153 22.1473 18.3083 21.8543L19.2223 20.9403L20.1373 21.8543C20.2833 22.0003 20.4753 22.0733 20.6673 22.0733C20.8593 22.0733 21.0513 22.0003 21.1973 21.8543C21.3443 21.7073 21.4173 21.5163 21.4173 21.3233C21.4173 21.1323 21.3443 20.9403 21.1973 20.7933L20.2833 19.8793Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9753 17.9043C13.6823 17.6113 13.2073 17.6113 12.9143 17.9043L12.0003 18.8183L11.0863 17.9043C10.7933 17.6113 10.3193 17.6113 10.0263 17.9043C9.87928 18.0503 9.80627 18.2423 9.80627 18.4343C9.80627 18.6263 9.87928 18.8183 10.0263 18.9653L10.9403 19.8793L10.0263 20.7943C9.73328 21.0873 9.73328 21.5613 10.0263 21.8543C10.3193 22.1473 10.7933 22.1473 11.0863 21.8543L12.0003 20.9403L12.9143 21.8543C13.0613 22.0013 13.2533 22.0743 13.4453 22.0743C13.6373 22.0743 13.8293 22.0013 13.9753 21.8543C14.1213 21.7083 14.1953 21.5163 14.1953 21.3243C14.1953 21.1333 14.1213 20.9403 13.9753 20.7943L13.0613 19.8793L13.9753 18.9653C14.2683 18.6723 14.2683 18.1973 13.9753 17.9043Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8802 6.61031H10.1202V5.93031C10.1402 4.90031 10.9602 4.09031 11.9702 4.07031C13.0102 4.11031 13.8602 4.90031 13.8802 5.92031V6.61031ZM12.7502 10.8503C12.7502 11.2703 12.4202 11.6003 12.0002 11.6003C11.5902 11.6003 11.2502 11.2703 11.2502 10.8503V10.2103C11.2502 9.80031 11.5902 9.46031 12.0002 9.46031C12.4202 9.46031 12.7502 9.80031 12.7502 10.2103V10.8503ZM15.3802 6.90031V5.91031C15.3502 4.07031 13.8302 2.57031 11.9802 2.57031H11.9602C10.1202 2.60031 8.65023 4.06031 8.62023 5.91031V6.90031C7.73023 7.34031 7.11023 8.25031 7.11023 9.30031V11.7703C7.11023 13.2503 8.32023 14.4503 9.80023 14.4503H14.2002C15.6802 14.4503 16.8802 13.2503 16.8802 11.7703V9.30031C16.8802 8.25031 16.2702 7.34031 15.3802 6.90031Z"
      />
    </svg>
  )
}
