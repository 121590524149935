import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function SvgComponent(props) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  return (
    <svg width={22} height={16} fill={darkTheme ? '#DECBFE' : '#AE9CCD'} {...props}>
      <path d="M18.929 11.226a2.39 2.39 0 0 0-.405.035 4.39 4.39 0 1 0-8.747-.731A2.866 2.866 0 1 0 8.583 16h10.346a2.387 2.387 0 1 0 0-4.774ZM1.612 3.327c1.227.475 2.872.736 4.631.736 1.76 0 3.404-.261 4.631-.736.98-.379 1.612-.887 1.612-1.296 0-.408-.633-.916-1.612-1.295C9.647.26 8.002 0 6.244 0c-1.76 0-3.405.261-4.632.736C.632 1.115 0 1.623 0 2.03c0 .409.633.917 1.612 1.296Z" />
      <path d="M1.612 8.127c1.227.475 2.872.736 4.631.736.957 0 1.88-.077 2.72-.224a5.635 5.635 0 0 1 1.34-1.917 5.622 5.622 0 0 1 2.183-1.27V3.91a6.263 6.263 0 0 1-1.161.581c-1.368.53-3.172.82-5.082.82-1.91 0-3.714-.29-5.081-.82A6.267 6.267 0 0 1 0 3.911v2.92c0 .41.633.918 1.612 1.296ZM5.795 10.11c-1.742-.041-3.374-.327-4.633-.814A6.256 6.256 0 0 1 0 8.715v2.922c0 .408.633.916 1.612 1.295.808.313 1.797.533 2.88.646a4.104 4.104 0 0 1 1.302-3.468Z" />
    </svg>
  )
}
